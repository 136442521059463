export default {
  message: {
    exportDataTitle: 'Laden Sie Listen als Excel-Datei herunter',
    mobile_phone: '(Mobiel) Telefoonnummer',
    commitment_letter_accept: 'Commitment letter accept',
    iban_number: 'IBAN-nummer',
    without_supplier: 'Ohne Lieferant',
    price_in_euro: 'Preis VK',
    is_default: 'Voreinstellung',
    attribute_option: 'Attributoption',
    image: 'Bild',
    show_stats_user: 'Shop-Statistik – BENUTZER',
    is_manual_order: 'Ist eine manuelle Bestellung',
    not_available: 'Zur Zeit nicht lieferbar',
    supplier_id: 'Lieferant ID',
    show_empty: 'Anzeigen bei Bestand = 0',
    vendor_id: 'Hersteller ID',
    archive_at: 'Archiv',
    exported_bridgestone: 'Exportiert Bridgestone',
    exported_engelbert: 'Exportiert Engelbert Strauss',
    exported_prime: 'Exportiert PrämieDirekt',
    exported_other: 'Andere exportiert',
    product_variant_id: 'Produktvarianten-ID',
    cancelled_at: 'Abgesagt um',
    shipped_at: 'Versand erfolgt am',
    product_id: 'Produkt-ID',
    quantity: 'Menge',
    qty: 'Menge',
    action_text: 'URL-Text',
    action_url: 'URL',
    token: 'UUID',
    marketing_option: 'Marketingoption',
    parent_id: 'Eltern ID',
    approval_id: 'Genehmigungs-ID',
    wholesaler_id: 'Wholesaler id',
    cart_id: 'Warenkorb-ID',
    order_date: 'Auftragsdatum',
    order_number: 'Bestellnummer',
    accept_date: 'Datum akzeptieren',
    wholesaler_dealer_id: 'Wholesaler dealer id',
    dealer_id: 'Dealer id',
    is_partial_delivery: 'Ist Teillieferung',
    order_completed: 'Bestellung abgeschlossen',
    order_cancelled: 'Bestellung storniert',
    date_delivery: 'Liefertermin',
    published: 'Veröffentlichen',
    properties: 'Eigenschaften',
    group: 'Gruppenname',
    gka: 'GKA',
    publish_at: 'Veröffentlichen',
    video: 'Video-URL',
    training_id: 'Training ID',
    correct: 'Ist richtig?',
    question_id: 'Frage-ID',
    body: 'Inhalt',
    size: 'Textgrösse',
    platform: 'Betriebssystem',
    is_added: 'Aktive Booster',
    numberOfSetsOfSeasonalTiresBudget: 'Einlagerungen pro Jahr',
    distributionWinter: 'Winter',
    distributionSummer: 'Sommer',
    overEighteenInch: 'Davon ≥ 18 Zoll (in %)',
    tireSalesNumber: 'Reifenumsatz pro Jahr ca. in Stk.',
    job_position_id: 'Position im betrieb',
    distributionAll: 'Alle seizoenen',
    budgetPremium: 'Premie',
    postal_code: 'Postleitzahl',
    legalForm: 'Rechtsform',
    budgetMid: 'Mid',
    budgetLow: 'Budget',
    group_id: 'Gruppen-ID',
    website: 'Webseite',
    customer_number: 'Kundennummer',
    user_id: 'Benutzer-ID',
    browser: 'Webbrowser',
    ip: 'IP Adresse',
    email_verified_at: 'E-Mail bestätigt unter',
    last_login: 'letzte Anmeldung',
    deleted_at: 'gelöscht am',
    is_active: 'Ist aktiv?',
    old: 'Alt',
    selectBooster: 'Booster auswählen',
    activeBooster: 'Aktiver Booster',
    activeBoosterText:
      'Möchten Sie einen aktiven Booster für den Benutzer aktivieren?',
    activeBoostersOne:
      'Werden Sie diesen Booster für jeden Benutzer aktivieren?',
    sum: 'Summe',
    boosterOnTop: 'Booster-Punkte (zusätzlich)',
    relevantBoosterPoints: 'Relevante Punkte für Booster',
    totalBefore: 'Gesamtpunktzahl vorher',
    standardPoints: 'Standardpunkte',
    boosterPoints: 'Booster-Punkte',
    allPoints: 'Alle punkte',
    pointsByUser: 'Laden Sie Punkte nach Benutzer herunter',
    pointsByGroup: 'Laden Sie Punkte nach Gruppe herunter',
    loginAs: 'Melden Sie sich als Benutzer an',
    positionX: 'Position x',
    positionY: 'Position y',
    textSize: 'Textgrösse',
    text: 'Text',
    publish: 'Veröffentlichen',
    certificate: 'Zertifikat',
    event: 'Event',
    remove_cs_number:
      'Sind Sie sicher, dass Sie die Kundennummer löschen möchten?',
    remove_order: 'Sind Sie sicher, dass Sie die Bestellung löschen möchten?',
    is_internal: 'Ist interner Benutzer?',
    selectAddressBtn: 'Adresse auswählen',
    currentBasket: 'Aktueller Warenkorb',
    loginEmail: 'Anmeldung',
    open_new_tab: 'In neuem Tab öffnen?',
    file: 'Datei',
    company_name: 'Firma',
    partner_name: 'Ansprechpartnerin',
    dealer: 'Händler',
    boosterImport: {
      label: 'CSV Datei.',
    },
    created_at: 'Erstellt am',
    full_text_search: 'Volltextsuche',
    logs: 'Logs',
    activity: 'Aktivität',
    booster_create: 'Booster erstellen',
    booster_wholesaler: 'Wholesaler',
    booster_products: 'Produktnamen',
    booster_customers: 'Kundennummern',
    booster_saison: 'Saison',
    booster_name: 'Name',
    booster_factor: 'Faktor',
    booster_from: 'Laufzeit von',
    booster_to: 'Laufzeit bis',
    booster_tires: 'Reifengrössen',
    booster_ipcs: 'IPCs',
    select: 'Bitte Wählen',
    booking: 'Buchung',
    credit: 'Gutschrift',
    deduction: 'Abzug',
    preview: 'Vorschau',
    subject: 'Betreff',
    customerNumber: 'Kundennummer',
    search: 'Suchen',
    customer_nr_name: 'Kundennummer / Name',
    selectAll: 'Alle auswählen',
    import: 'Importieren',
    articleNumber: 'Artikelnummer',
    inform_user: 'Kunden informieren',
    leaveComment: 'Hinterlasse hier einen Kommentar',
    user_new: 'Neuer Benutzer',
    postalcode: 'PLZ',
    city: 'Ort',
    residence: 'Ist Wohnsitz',
    country: 'Land',
    street: 'Strasse und Hausnummer',
    salutation: 'Anrede',
    suffix: 'Adresszusatz',
    emailVerified: 'E-Mail verifiziert am',
    registerAt: 'Registriert am',
    phone: 'Telefon',
    optinAt: 'OptIn am',
    fax: 'Fax',
    date: 'Datum',
    optinToken: 'OptIn-Token',
    language: 'Sprache',
    company: 'Firma',
    forename: 'Vorname',
    lastname: 'Nachname',
    title: 'Titel',
    remove: 'Löschen',
    actions: 'Aktionen',
    userData: 'Benutzerdaten',
    profile: 'Profil',
    addresses: 'Adressen',
    address: 'Adresse',
    show0Products: 'Anzeigen bei Bestand = 0',
    notAvailable: 'Zur Zeit nicht lieferbar',
    limitless: 'Grenzenlos',
    isDefault: 'Voreinstellung',
    data: 'Daten',
    importText: 'Bitte wähle eine Datei zum Importieren aus:',
    upload: 'Hochladen',
    users: 'Benutzer',
    permission: 'Recht',
    save: 'Speichern',
    downloadOrderStats: 'Statistiken herunterladen',
    downloadProductStats: 'Download Produktstatistiken herunter',
    category: 'Kategorie',
    order: 'Bestellung',
    permissions: 'Rechte',
    options: 'Optionen',
    attributes: 'Attribute',
    add_attribute: 'Attribute hinzufügen',
    login: 'Einloggen',
    logout: 'Ausloggen',
    email: 'E-Mail',
    username: 'Benutzer',
    filter: 'Filtern',
    name: 'Name',
    points: 'Punkte',
    sku: 'SKU',
    count: 'Anzahl',
    action: 'Aktion',
    active: 'Aktiv',
    type: 'Typ',
    status: 'Zustand',
    main: 'Hauptprodukt',
    vendor: 'Hersteller',
    supplier: 'Lieferant',
    variant: 'Produktvariante',
    description: 'Beschreibung',
    short_description: 'Kurzbeschreibung',
    upload_text: 'Produktbilder-Upload',
    product_new: 'Neues Einzel-Produkt',
    product_new_variants: 'Neues Varianten-Produkt',
    training_new: 'Neues Training',
    btraining_new: 'Neues BS Schulunge',
    images: 'Bilder',
    roles: 'Rollen',
    search_products: 'Produktsuche',
    search_orders: 'Aufträge durchsuchen',
    search_type: 'Suchtyp',
    hunter_new: 'Neuer Werkstatteinrichtungen',
    first_name: 'Vorname',
    last_name: 'Nachname',
    copy: 'Kopieren',
    add_empty_variant: 'Leere Variante hinzufügen',
    menu: {
      shopLimits: 'Shop limits',
      default_banners: 'Default banners',
      import_adex: 'Adex import',
      adex: 'Adex',
      logs: 'Logs',
      activities: 'Aktivitäten',
      booster_setup: 'Booster einrichten',
      booster_list: 'List',
      customer: 'Kunden',
      'users-roles': 'Benutzerverwaltung',
      roles: 'Rollen',
      booster: 'Booster',
      cheques: 'Schecks & Abzüge',
      points: 'Punkteverwaltung',
      users: 'Benutzer',
      users_list: 'Benutzerliste',
      user_new: 'Neuer Benutzer',
      users_deleted: 'Gelöschte Benutzer',
      perms: 'Rechte',
      'products-categories': 'Prämienshop',
      categories: 'Kategorien',
      categories_list: 'Kategorienliste',
      category_new: 'Neue Kategorie',
      orders: 'Bestellungen',
      products: 'Produkte',
      products_list: 'Produktliste',
      product_new: 'Neues Einzel-Produkt',
      product_new_variants: 'Neues Varianten-Produkt',
      attributes: 'Attribute',
      attributes_list: 'Liste der Attribute',
      attribute_new: 'New Attribute',
      options: 'Optionen',
      options_list: 'Optionsliste',
      options_new: 'Neue Option',
      vendors: 'Hersteller',
      vendors_list: 'Herstellerliste',
      vendor_new: 'Neuer Hersteller',
      suppliers: 'Lieferanten',
      suppliers_list: 'Lieferantenliste',
      supplier_new: 'Neuer Lieferant',
      sliders: 'Main Teaser Slider',
      sliders_list: 'Liste',
      slider_new: 'Neuer Main Teaser Slider',
      featured: 'Teaser Produkte',
      commands: 'Befehle',
      command_ipc: 'IPC',
      command_users: 'Benutzer importieren',
      'trainings-schulungen': 'Trainings & Schulungen',
      trainings: 'Online Trainings',
      trainings_list: 'Liste',
      training_new: 'Neue hinzufügen',
      btrainings: 'BS Schulungen',
      btrainings_list: 'Liste',
      btraining_new: 'Neue hinzufügen',
      management: 'Inhaltsverwaltung',
      textpages: 'Textseiten',
      textpages_list: 'Liste',
      text_page_new: 'Neue hinzufügen',
      settings: 'Nachrichten-Manager',
      messages: 'Nachrichten anlegen',
      messages_list: 'Liste',
      messages_new: 'Neue hinzufügen',
      threads: 'Autom. Mails einrichten',
      requests: 'Benutzeranfragen',
      product_trainings_request: 'Online Trainings',
      bridgestone_courses_request: 'BS Schulungen',
      external_trainings_request: 'Externe Schulungen',
      hunter_request: 'Werkstatteinrichtungen',
      investments_request: 'Investitionsunterstützung',
      news_feed: 'Neuigkeiten',
      news_feed_list: 'Liste',
      news_feed_new: 'Neue hinzufügen',
      salespromotions: 'Verkaufsförderung',
      imported_products: 'Importierte Produkte',
      hunter_list: 'Liste',
      hunters: 'Werkstatteinrichtungen',
      hunter_new: 'Neuer Werkstatteinrichtungen',
      groups: 'Gruppen',
      groups_list: 'Gruppenliste',
      group_new: 'Neue Gruppe',
      history: 'Anmeldeverlauf',
      history_list: 'Anmeldeverlauf',
      prices: 'Pricat',
      prices_list: 'Liste',
      price_new: 'Neue Pricat',
      price_categories: 'Preislisten',
      price_categories_list: 'Liste',
      price_categories_new: 'Neue Preislisten',
      inactive_users: 'Neuanmeldungen<br/>freigeben',
      customer_numbers_to_approve: 'Neuanmeldungen<br/>ohne Nummer',
      complaints: 'Beanstandungsformular',
      orders_list: 'Liste',
      orders_new: 'Neue Bestellungen',
      points_list: 'Punkteliste',
      kickback_list: 'Kickback fee',
      booster_preview: 'Vorschau',
      frontend_menu: 'Frontend-Menü',
      frontend_menu_list: 'Liste',
      frontend_menu_new: 'Neue',
      mediapages: 'Medienbibliothek',
      mediapages_list: 'Liste',
      media_page_new: 'Neu',
      uberall_request: 'Uberall',
      history_hour: 'Anmeldezeiten',
      wholesaler_management: 'Wholesaler management',
      in_active_cs_nr: 'Deaktiviert Nummern',
      mediapages_file_list: 'Dateiliste',
      vouchers: 'Vouchers',
      vouchers_list: 'List',
      vouchers_new: 'Neu',
      brochure_prices_list: 'Liste',
      brochure_prices: 'Broschüren & Preislisten',
      brochure_prices_new: 'Neu',
      main_boxes: 'Teaser Boxes',
      main_boxes_list: 'Liste',
      main_box: 'Bearbeiten',
      main_boxes_new: 'Neu',
    },
    breadcrumb: {
      shopLimits: 'Shop limits',
      default_banners: 'Default banners',
      import_adex: 'Adex import',
      adex: 'Adex',
      activities: 'Aktivitäten',
      booster_setup: 'Booster einrichten',
      booster_list: 'List',
      customer: 'Kunden',
      booster: 'Booster',
      cheques: 'Schecks & Abzüge',
      user_edit: 'Benutzer bearbeiten',
      users: 'Benutzer',
      user: 'Benutzer',
      vendors: 'Hersteller',
      vendor_new: 'Neuer Hersteller',
      suppliers: 'Lieferanten',
      supplier_new: 'neuer Lieferant',
      home: 'Home',
      perms: 'Rechte',
      roles: 'Rollen',
      roles_list: 'Liste',
      dashboard: 'Dashboard',
      orders: 'Bestellungen',
      orders_edit: 'Bestellung bearbeiten',
      orders_view: 'Bestellung',
      orders_list: 'Liste',
      salespromotions: 'Verkaufsförderung',
      salespromotions_list: 'Liste',
      salespromotions_view: 'Sicht',
      categories: 'Kategorien',
      categories_list: 'Liste',
      role: 'Rolle',
      category: 'Kategorie',
      products: 'Produkte',
      products_list: 'Liste',
      product: 'Produkt',
      product_category: 'Kategorie-Produkte',
      product_new: 'Neues Einzel-Produkt',
      product_new_variants: 'Neues Varianten-Produkt',
      product_variants: 'Varianten-Produkt',
      attributes: 'Attribute',
      attributes_list: 'Liste',
      attribute: 'Attribut',
      category_new: 'Neue Kategorie',
      attribute_edit: 'Attribut bearbeiten',
      options_new: 'Neue Option',
      options: 'Optionen',
      options_list: 'Liste',
      options_edit: 'Option bearbeiten',
      userEdit: 'Benutzer bearbeiten',
      users_list: 'Liste',
      users_deleted: 'Gelöschte Benutzer',
      vendor_edit: 'Hersteller bearebiten',
      vendors_list: 'Liste',
      supplier_edit: 'Lieferant bearbeiten',
      suppliers_list: 'Lieferantenliste',
      user_new: 'Neuer Benutzer',
      trainings: 'Trainings',
      trainings_list: 'Liste',
      btrainings: 'Online-Trainings',
      btrainings_list: 'Liste',
      btraining: 'Online-Trainings',
      training_new: 'Neues Training',
      btraining_new: 'Neues BS Schulunge',
      training_edit: 'Training bearbeiten',
      training: 'Training',
      command_ipc: 'IPC',
      sliders: 'Main Teaser Slider',
      sliders_list: 'Liste',
      slider_new: 'Neuer Main Teaser Slider',
      slider: 'Main Teaser Slider bearbeiten',
      command_users: 'Benutzer Importieren',
      textpages: 'Textseiten',
      text_page_new: 'Neue Textseite',
      textpage: 'Textseiten',
      textpages_list: 'Liste',
      messages: 'Nachrichten',
      messages_list: 'Liste',
      messages_new: 'Neue Nachricht',
      messages_edit: 'Nachricht bearbeiten',
      threads: 'Betreff',
      featured: 'Teaser Produkte',
      attribute_new: 'Neues Attribut',
      product_trainings_request: 'Anfragen zu Online Trainings',
      bridgestone_courses_request: 'Anfragen zu Bridgestone-Schulungen',
      external_trainings_request: 'Anfragen zu externen Schulungen',
      hunter_request: 'Werkstatteinrichtungen',
      investments_request: 'Investitionsunterstützung',
      news_feed: 'Neuigkeiten',
      news_feed_list: 'Liste',
      news_feed_new: 'Neuer Newsfeed',
      news_feed_edit: 'Newsfeed bearbeiten',
      news_feed_slider: 'Neuer Newsfeed-Slider',
      imported_products: 'Importierte Produkte',
      hunter_list: 'Liste',
      hunters: 'Werkstatteinrichtungen',
      hunter_new: 'Neuer Werkstatteinrichtungen',
      hunter_edit: 'Werkstatteinrichtungen bearbeiten',
      groups: 'Gruppen',
      groups_list: 'Gruppenliste',
      group_new: 'Neue Gruppe',
      group_edit: 'Gruppe Bearbeiten',
      history: 'Anmeldeverlauf',
      history_list: 'Anmeldeverlauf',
      history_view: 'Benutzer-Anmeldeverlauf',
      prices: 'Pricat',
      prices_list: 'Liste',
      price_new: 'Neue Pricat',
      price: 'Pricat bearbeiten',
      price_categories: 'Preisliste',
      price_categories_list: 'Liste',
      price_categories_new: 'Neue Preislisten',
      price_category: 'Preisliste',
      inactive_users: 'Neuanmeldungen freigeben',
      inactive_users_list: 'Liste',
      customer_numbers_to_approve: 'Neuanmeldungen ohne Nummer',
      complaints: 'Beanstandungsformular',
      complaints_list: 'Liste',
      complaints_view: 'Beschwerde',
      orders_new: 'Neue Bestellungen',
      points_list: 'Punkteliste',
      kickback_list: 'Kickback fee',
      booster_preview: 'Vorschau',
      frontend_menu: 'Frontend-Menü',
      frontend_menu_list: 'Liste',
      frontend_menu_new: 'Neue',
      mediapages: 'Medienbibliothek',
      mediapages_list: 'Liste',
      media_page_new: 'Neu',
      mediapage: 'Bearbeiten',
      uberall_request: 'Uberall',
      history_hour: 'Anmeldezeiten',
      wholesaler_management: 'Wholesaler management',
      in_active_cs_nr: 'Deaktiviert Nummern',
      mediapages_file_list: 'Dateiliste',
      vouchers: 'Vouchers',
      vouchers_list: 'List',
      vouchers_new: 'Neu',
      brochure_prices_list: 'Liste',
      brochure_prices: 'Broschüren & Preislisten',
      brochure_prices_new: 'Neu',
      main_boxes: 'Teaser Boxes',
      main_boxes_list: 'Liste',
      main_box: 'Bearbeiten',
      main_boxes_new: 'Neu',
    },
    orders: {
      view: 'Bestellung',
      new: 'Neue Bestellung',
      list: 'Bestellungen',
      edit: 'Bestellung bearbeiten',
      number: 'Bestellnummer',
      date: 'Bestelldatum',
      state: 'Bestellstatus',
      status: {
        created: 'Erstellt',
        confirm_order: 'Bestellung bestätigen',
        ready_for_shipment: 'Versandfertig',
        rejected: 'Abgelehnt',
        processed: 'Verarbeitet',
        shipped: 'Versendet',
        returned: 'Ist zurückgekommen',
        finished: 'Fertig',
      },
    },
    salespromotions: {
      view: 'Verkaufsförderung',
      list: 'Verkaufsförderungen',
      premium: 'DRIVEOURBEST',
      guarantee: 'TYREPROTECT',
      bonusCampaign: 'REIFENHITS',
      advantage: 'VORTEILSAKTION',
    },
    countries: {
      de: 'Deutschland',
      at: 'Österreich',
      ch: 'Schweiz',
      nl: 'Niederlande',
      fr: 'Frankreich',
    },
    messages: {
      list: 'Nachrichten',
      new: 'Neue Nachricht',
      edit: 'Nachricht bearbeiten',
      title: 'Titel',
      content: 'Inhalt',
      empty: 'Keine Nachrichten',
      direct: 'Direktnachricht',
      toDealers: 'An Händler',
      sendEmail: 'E-Mail senden',
      selectWholesalers: 'Grosshändler auswählen',
      directWholesaler: 'vom Grosshändler zum Händler',
      saveAndSend: 'Speichern und senden',
      users: 'Benutzer auswählen (min. 3 Zeichen)',
      sent: 'Gesendet',
      externalTrainingPoints:
        'Externe Training / Investitionsunterstützung Punkte',
      externalTrainingBank:
        'Externe Training / Investitionsunterstützung Kontoinhaber',
      externalTrainingIBAN: 'Externe Training / Investitionsunterstützung IBAN',
      onlineTrainingTitle: 'Online Training - Titel',
      onlineTrainingPoints: 'Online Training - Punkte',
      hunterTitle: 'Werkstatteinrichtungen - Titel',
      hunterPoints: 'Werkstatteinrichtungen - Punkte',
      hunterPrice: 'Werkstatteinrichtungen - Preis',
      bTrainingTitle: 'Bridgestone Schulungen - Titel',
      bTrainingPoints: 'BS Schulungen - Points',
      pointsProfileComplete: 'Punkte - Profil vervollständigt',
      orderProducts: 'Produkte in der Bestellung',
    },
    messages_alert: {
      title: 'Nachright löschen',
      desc: 'Möchten Sie die Nachricht wirklich löschen?',
    },
    groups_alert: {
      title: 'Gruppe löschen',
      desc: 'Möchten Sie die Gruppe wirklich löschen?',
    },
    vendors_alert: {
      title: 'Hersteller löschen',
      desc: 'Möchten Sie den Hersteller wirklich löschen?',
    },
    suppliers_alert: {
      title: 'Lieferant löschen',
      desc: 'Möchten Sie den Lieferanten wirklich löschen?',
    },
    threads: {
      title: 'Betreff',
      choose: 'Bitte Nachricht auswählen',
      order: 'Betreff für Bestellungen auswählen',
      salespromotions: 'Betreff für Verkaufsförderung auswählen',
      externalTraining: 'Betreff für Externe Schulungen auswählen',
      investment: 'Betreff für investitionsunterstützung',
      onlineTraining: 'Betreff für Online Training',
      hunter: 'Betreff für Werkstatteinrichtungen',
      bTraining: 'Betreff für Bridgestone Schulungen',
      profileComplete: 'Betreff für Profilvervollständigung',
      activeUser: 'Aktive Benutzernachricht',
    },
    userGroup: {
      name: 'Gruppenname',
      gka: 'GKA',
    },
    role: 'Rolle',
    user: 'Benutzer',
    vat: 'Steuernummer',
    categories: 'Kategorien',
    count_users: 'Anzahl Benutzer',
    product: 'Produkt',
    count_perms: 'Anzahl Rechte',
    count_products: 'Anzahl Produkte',
    count_options: 'Anzahl Optionen',
    attribute_new: 'Neues Attribut',
    category_new: 'Neue Kategorie',
    category_title: 'Kategorietitel',
    category_desc: 'Kategorie beschreibung',
    category_image: 'Kategorie Bild',
    category_link: 'Kategorie Link',
    category_alert: {
      title: 'Kategorie löschen',
      desc: 'Möchten Sie die Kategorie wirklich löschen?',
    },
    product_alert: {
      title: 'Produkt löschen',
      desc: 'Möchten Sie das Produkt wirklich löschen?',
    },
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    options_new: 'Neue Option',
    subcategory: 'Unterkategorie',
    uploadText: 'Ein Bild hochladen',
    startImport: 'Import starten',
    importStarted: 'Import wurde gestartet',
    connection: 'Verbindung',
    variants: 'Varianten',
    add_variant: 'Variante hinzufügen',
    vendor_new: 'Neuer Hersteller',
    training_edit: 'Training bearbeiten',
    vendors: 'Hersteller',
    supplier_new: 'Neuer Lieferant',
    suppliers: 'Lieferanten',
    training: 'Training',
    commands: {
      ipc: 'IPC-Importer',
      users: 'Benutzer Importieren',
    },
    userImport: {
      label: 'Nur XLSX-Typ',
    },
    questions: 'Fragen',
    correctAnswer: 'Richtige Antwort',
    validation: {
      required: 'Field is required',
    },
    edit_question: 'Fragen bearbeiten',
    edit_btraining_Detail: 'Bridgestone-Schulungsdetails bearbeiten',
    video_url: 'Video-URL',
    save_new_question: 'Neue Frage speichern',
    reward: 'Abbildung der Prämie - nicht erforderlich',
    poster: 'Posterbild',
    season: 'Saison',
    answers: 'Antworten',
    training_details: 'Trainingsdetails',
    extras: 'Weitere Informationen',
    duration: 'Dauer',
    price: 'Preis',
    min_participant: 'Mindestteilnehmerzahl',
    max_participant: 'Maximale Teilnehmerzahl',
    choose_trainingType: 'Trainingsart auswählen',
    update: 'Aktualisieren',
    cost_type: 'Kostentyp',
    save_detail: 'Einzelheiten speichern',
    slider_new: 'Neuer Main Teaser Slider',
    sliders: 'Main Teaser Slider',
    groups: 'Gruppen',
    slider: 'Main Teaser Slider bearbeiten',
    choose_showHideText: 'Text ein-/ausblenden',
    text_page_new: 'Neue Textseite',
    text_pages: 'Textseiten',
    text_page: 'Textseite',
    featured: {
      title: 'Teaser Produkte',
      bestseller: 'Meistgekauft',
      seasonal: 'Saisonales',
      affordable: 'Fast geschafft',
      autoBestsellers: 'Automatisch berechnete Bestseller anzeigen',
      affordableOffsetLabel: 'Maximaler Preisausgleich',
      affordableLimitLabel: 'Produktlimit anzeigen',
    },
    seasons: {
      all: 'Alle Jahreszeiten',
      summer: 'Sommer',
      winter: 'Winter',
      general: 'Allgemein',
    },
    addAddress: 'Adresse hinzufügen',
    administration: 'Verwaltung',
    password: 'Passwort',
    toastMessages: {
      success: 'Die Operation wurde erfolgreich abgeschlossen',
      error: 'Unerwarteter Fehler, bitte versuchen Sie es später erneut',
      invalid: 'Ungültige Werte im Formular',
      done: 'Erledigt!',
      requestProcessed: 'Die Anfrage wird bearbeitet. Einen Moment bitte...',
      noQuestions: 'Die Schulung muss mindestens 1 Frage enthalten',
      pointsAdded: 'Buchung erfolgt',
      selectUser: 'Wählen Sie einen Benutzer aus der Liste aus',
      selectOneField: 'Wählen Sie ein Feld aus',
      pointRemoveSoon: 'Punkte werden bald gelöscht',
      dataLoading: 'Die Daten werden geladen, bitte warten',
    },
    stats: {
      users_list: 'Anzahl der Benutzer',
      external_trainings_request: 'Externe Schulungen',
      product_trainings_request: 'Online Trainings',
      bridgestone_courses_request: 'Bridgestone Schulungen',
      hunter_request: 'Werkstatteinrichtungen',
      investments_request: 'Investitionsunterstützung',
      orders_list: 'Bestellungen',
      history_list: 'Anmeldeverlauf',
      salespromotions_list: 'Verkaufsförderung',
      inactive_users: 'Neuanmeldungen freigeben',
      complaints_list: 'Beanstandungsformular',
      points_list: 'Punkteliste',
      kickback_list: 'Kickback fee',
      history_hour: 'Anmeldezeiten',
      tyre_list: 'Reifen',
    },
    product_trainings_request: 'Anfragen zu Online Trainings',
    bridgestone_courses_request: 'Anfragen zu Bridgestone-Schulungen',
    attempt_number: 'Versuchsnummer',
    thirdPartyInvoice: 'Rechnung Drittanbieter',
    userInvoice: 'Rechnung des Benutzers',
    bankAccountOwner: 'Kontoinhaber',
    open: 'Öffnen',
    url: 'URL',
    url_text: 'URL-Text',
    choose_posterSide: 'Bildseite: ',
    left: 'Links',
    right: 'Rechts',
    disclaimer: 'Haftungsausschluss',
    supply: {
      history: 'Versorgungshistorie',
      quantity: 'Menge',
    },
    copyAsMain: 'Als Hauptteaser kopieren',
    news_feed_slider_list: 'Die Liste des Newsfeed-Sliders',
    usersPage: {
      boostersToApprove: 'Booster zur Genehmigung',
      lastLogin: 'Letzte anmeldung',
      customerNumbers: 'Kundennummer',
      branches: 'Geäst',
      balance: 'Kontostand',
      orders: 'Bestellungen',
      groups: 'Gruppen',
      notes: 'Anmerkungen',
      salesStaff: 'Aussendienst',
      parentBranch: 'Hauptgeschäft',
      childBranch: 'Filiale',
      addWholesaler: 'Grosshändler hinzufügen',
      addBranch: 'Filiale hinzufügen',
      tooltip:
        'Um eine Filiale hinzuzufügen, müssen Sie die Kundennummer das Hauptgeschäft speichern',
      tooltipRmCnLocal: 'Lokale Kundennummer entfernen',
      tooltipRmCnDB: 'Kundennummer für Datenbank entfernen',
      statement: 'Kontoauszug',
      bank: {
        date: 'Datum',
        supplySourceReason: 'Bezugsquelle / Grund',
        customerNumber: 'Kdnr.',
        article: 'Artikel',
        brand: 'Marke',
        product: 'Produkt',
        type: 'Typ',
        inch: 'Zoll',
        season: 'Saison',
        amount: 'Menge',
        otherActions: 'Sonstige Handlung bzw. Ursache',
        totalPoints: 'Punkte Gesamt',
        loadMore: 'Weitere 20 umsätze laden',
        seasons: {
          all: 'All Season',
          summer: 'Summer',
          winter: 'Winter',
          general: 'General',
        },
      },
      advanced: 'Erweitert',
      deleteUser: 'Benutzer löschen',
      deleteUserConfirm: 'Möchten Sie den Benutzer wirklich löschen?',
      selectRole: 'Rolle auswählen',
      selectWholesaler: 'Grosshändler auswählen',
    },
    recoverUser: 'Benutzer zurückholen',
    pieces_per_set: 'Verpackungseinheiten',
    new: 'Neu',
    all: 'Alle',
    showAddress: 'Adresse zeigen',
    showGroup: 'GKA & Bl-Alt zeigen',
    showPoints: 'Punktestände anzeigen',
    showKickbacks: 'Show kickback fee',
    external_sales: 'Aussendienst',
    login_from: 'Letzter Login (von)',
    login_to: 'Letzter Login (bis)',
    check: 'Prüfen',
    reject: 'Ablehnen',
    reason: 'Grund',
    active_by: 'Aktiviert von',
    sharePassword: 'Teilen Sie dieses Passwort mit dem neuen Administrator',
    activeBtn: 'Jetzt aktivieren',
    newUserVerify: 'Überprüfung der neuen Registrierung',
    complaintForm: {
      sections: {
        general: {
          title: 'allgemein',
          ordinance: 'Ordnungsbegriff des Händlers',
          generalEmail: 'Ihre E-Mailadresse',
          country: 'Land',
          pickupLocation: 'Abholort',
        },
        regulator: {
          title: 'anschrift reifenhändler - regulierer',
        },
        vehicleDetails: {
          title: 'Angaben zum Fahrzeug',
          vehicleTyres: 'Fahrzeug/Reifen',
          vehicleManufacturer: 'Fahrzeughersteller',
          vehicleModelType: 'Fahrzeugmodell und Typbezeichnung lt. Zulassung',
          firstRegistration: 'Erstzulassung',
          enginePower: 'Motorleistung (KW z.B. 150)',
          originalEquipment: 'Erstausrüstung',
          tireMilageHours: 'Laufleistung Reifen (km) / Betriebsstunden',
          milageOrHours: 'Km oder Stunden (z.B. 2000)',
        },
        tyre: {
          tirePosition: 'Reifenposition',
          tireSizeExample: 'Reifengrösse (z.B. 215/60/16)',
          speedLoadIndex: 'Geschwindigkeits- / Lastindex',
          profileDesignation: 'Profilbezeichnung inc. Spezifikation',
          dot: 'DOT',
          truckSerialNo: 'Serien-Nr. (nur bei LKW Reifen)',
          complaintReason: 'Beanstandungsgrund',
          isTyreReplaced: 'Wurden die Reifen bereits ersetzt?',
          ifYesTyre: 'Falls ja, Marke der Ersatzreifen',
          clerkName: 'Name des Sachbearbeiters',
          complaintReasonText: 'Beanstandungsgrund Text',
        },
      },
    },
    yesText: 'Ja',
    noText: 'Nein',
    tyre: 'Reifen',
    buttons: {
      close: 'Schliessen',
      placeOrder: 'Verbindlich Bestellen',
      download: 'Herunterladen',
      approve: 'Genehmigen',
      deleteOrder: 'Auftrag löschen',
      archive: 'Archiv',
      acceptAll: 'Alle akzeptieren',
      advanceSearch: 'Erweiterte Suche',
      addMenu: 'Menü hinzufügen',
      setMaintenance: 'Wartung einstellen',
      hideMaintenance: 'Wartung ausblenden',
      deactivate: 'Deaktivieren',
      importSelected: 'Ausgewählte importieren',
      importArticleNumbers: 'Diese Artikel importieren',
      clearFilters: 'Filter zurücksetzen',
      toSearch: 'Suchen',
      delete: 'Löschen',
    },
    others: 'Andere',
    archive_training: 'Möchten Sie diese Schulung wirklich archivieren?',
    delete_menu: 'Möchten Sie den Menüpunkt löschen?',
    no_entries: 'Keine Einträge',
    external_url: 'Externe URL',
    incomplete_address: 'Adresse ist unvollständig. überprüfen Sie bitte',
    request_from: 'Anfrage vom',
    let_see_points: 'Lassen Sie den Benutzer Marketingpunkte sehen',
    let_see_kickback: 'Lassen Sie den Benutzer die Kickback-Gebühr sehen',
    major: 'Grep',
    media_category: 'Kategorie „Medien“',
    dragHere: 'Legen Sie die Dateien hier ab ...',
    addEmbbeded: 'Fügen Sie eingebetteten Code hinzu',
    canReceivePoints: 'Kann Punkte erhalten',
    canUsePoints: 'Kann Punkte gebrauchen',
    wholesaler_management: {
      modal: {
        description:
          'Welche Daten kann der Grosshändler in den Händlerdetails einsehen?',
      },
    },
    personalData: 'Persönliche daten',
    operatingData: 'Betriebsdaten',
    orderDelivery: 'Lieferadresse',
    diffDeliveryAddres: 'Lieferadresse hinzufügen',
    current_tyres_amount: 'Reifenmenge',
    current_points: 'Punktestand',
    current_kickbacks: 'Kick-back',
    change_role_info:
      'Beim Ändern einer Rolle im <b>Bridgestone PLUS-System</b> werden Verbindungen gelöscht, die mit einer bestimmten Rolle verknüpft sind, z. B. Punkte, Kundennummern, Verbindungen zwischen einem Bridgestone-Agenten und einem Händler, Bestellungen usw. Sind Sie sicher, dass Sie diese Änderung vornehmen möchten? Weil es keinen Weg zurück gibt?',
    firstRegistration: 'Erstanmeldung',
    mediaCategories: {
      products: 'Produkte',
      brand_and_solutions: 'Marke & Lösungen',
      season_and_promotions: 'Saison & Aktionen',
      format: 'Format',
      cars_and_vans: 'Pkw & Transporter',
      truck_and_buses: 'Lkw & Bus',
      motorcycle: 'Motorrad',
      agriculture: 'Landwirtschaft',
      construction_machinery: 'Baumaschinen',
      bridgestone: 'Bridgestone',
      firestone: 'Firestone',
      bandag: 'Bandag',
      bleetcare: 'Fleetcare',
      webfleet: 'Webfleet',
      summer: 'Sommer',
      winter: 'Winter',
      all_season: 'All Season',
      promotions: 'Aktionen',
      video_in_store: 'Video (Instore)',
      video_online: 'Video (Online)',
      banner_website: 'Banner (Webseite)',
      banner_social_media: 'Banner (Social Media)',
      logos: 'Logos',
      b_drive: 'B-drive',
      media_library: 'Media library',
      media_packages: 'Media packages',
    },
    fileType: {
      label: 'File type',
      video: 'Video',
      image: 'Image',
      embeded: 'Embeded',
    },
    deleteMediaModal: {
      title: 'Mediensammlung löschen',
      description:
        'Bestätigen Sie das Löschen der Dateisammlung? Alle verbundenen Dateien werden gelöscht.',
    },
    deleteMediaFileModal: {
      title: 'Datei aus Sammlung löschen',
      description: 'Möchten Sie die Datei aus dieser Sammlung löschen?',
    },
    languages: {
      de: 'Deutsch',
      ch: 'Deutsch',
      fr: 'Französisch',
      en: 'Englisch',
      nl: 'Niederländisch',
      it: 'Italienisch',
    },
    colleague: 'Kollege',
    tradeInDate: 'Inzahlungnahmedatum',
    vouchers: {
      points: 'Anzahl der Punkte pro Gutschein',
      amount: 'Anzahl Gutscheine',
    },
    selectText: 'Wählen',
    brochureCategories: {
      products: 'Produkte',
      brand_and_solutions: 'Marke & Lösungen',
      season_and_promotions: 'Saison & Aktionen',
      format: 'Format',
      cars_and_vans: 'Pkw & Transporter',
      truck_and_buses: 'Lkw & Bus',
      motorcycle: 'Motorrad',
      agriculture: 'Landwirtschaft',
      construction_machinery: 'Baumaschinen',
      bridgestone: 'Bridgestone',
      firestone: 'Firestone',
      bandag: 'Bandag',
      bleetcare: 'Fleetcare',
      webfleet: 'Webfleet',
      material: 'Materialien',
      brochures: 'Produktbrochuren',
      price_list: 'Preislisten',
      materials: 'Materialien',
    },
    deleteMainBox: {
      title: 'Hauptfeld löschen',
      description: 'Möchten Sie diesen Artikel wirklich löschen?',
    },
  },
}
