export default {
  message: {
    exportDataTitle: 'Scarica gli elenchi come file Excel',
    mobile_phone: 'Numero di telefono (cellulare)',
    commitment_letter_accept: 'Lettera di impegno accettata',
    iban_number: 'numero IBAN',
    without_supplier: 'Senza fornitore',
    price_in_euro: 'Prezzo al dettaglio',
    is_default: 'Predefinito',
    attribute_option: 'Opzione attributo',
    image: 'Immagine',
    show_stats_user: 'Statistiche del negozio - UTENTI',
    is_manual_order: 'È un ordine manuale',
    not_available: 'Attualmente non disponibile al momento',
    supplier_id: 'Identificativo del fornitore',
    show_empty: 'Visualizza se inventario = 0',
    vendor_id: 'Identificativo del produttore',
    archive_at: 'Archivio',
    exported_bridgestone: 'Esporta Bridgestone',
    exported_engelbert: 'Esporta Engelbert Strauss',
    exported_prime: 'Esporta PremiumDirect',
    exported_other: 'Altri esportarono',
    product_variant_id: 'ID variante prodotto',
    cancelled_at: 'Annullato alle',
    shipped_at: 'La spedizione avviene il',
    product_id: 'Codice prodotto',
    quantity: 'Folla',
    qty: 'Folla',
    action_text: "Testo dell'URL",
    action_url: 'URL',
    token: 'UUID',
    marketing_option: 'Opzione di marketing',
    parent_id: 'ID genitore',
    approval_id: 'ID di approvazione',
    wholesaler_id: 'Codice grossista',
    cart_id: 'ID del carrello',
    order_date: "Data dell'ordine",
    order_number: "Numero d'ordine",
    accept_date: 'Accetta data',
    wholesaler_dealer_id: 'ID rivenditore grossista',
    dealer_id: 'ID del rivenditore',
    is_partial_delivery: 'È la consegna parziale',
    order_completed: 'ordine completato',
    order_cancelled: 'ordine cancellato',
    date_delivery: 'data di consegna',
    published: 'Pubblicare',
    properties: 'Caratteristiche',
    group: 'Nome del gruppo',
    gka: 'GKA',
    publish_at: 'Pubblicare',
    video: 'URL del video',
    training_id: 'ID della formazione',
    correct: 'È giusto?',
    question_id: 'ID della domanda',
    body: 'Contenuti',
    size: 'Dimensione del testo',
    platform: 'sistema operativo',
    is_added: 'Booster attivi',
    numberOfSetsOfSeasonalTiresBudget: "stoccaggio all'anno",
    distributionWinter: 'inverno',
    distributionSummer: 'Estate',
    overEighteenInch: 'Di cui ≥ 18 pollici (in %)',
    tireSalesNumber: "Vendite di pneumatici all'anno ca.",
    job_position_id: 'Posizione in azienda',
    distributionAll: 'Tutto seizoen',
    budgetPremium: 'Premio',
    postal_code: 'Codice Postale',
    legalForm: 'forma giuridica',
    budgetMid: 'Metà',
    budgetLow: 'bilancio',
    group_id: 'Identificativo del gruppo',
    website: 'sito web',
    customer_number: 'Numero cliente',
    user_id: 'ID utente',
    browser: 'Webbprogramma di navigazione in reterowser',
    ip: 'indirizzo IP',
    email_verified_at: 'E-mail confermata a',
    last_login: 'Ultimo accesso',
    deleted_at: 'cancellato il',
    is_active: 'È attivo?',
    old: 'Vecchio',
    selectBooster: 'Seleziona il potenziamento',
    activeBooster: 'Booster attivo',
    activeBoosterText: "Vuoi abilitare un booster attivo per l'utente?",
    activeBoostersOne: 'Abiliterai questo booster per ogni utente?',
    sum: 'Summe',
    boosterOnTop: 'Booster-Punkte (zusätzlich)',
    relevantBoosterPoints: 'Relevante Punkte für Booster',
    totalBefore: 'Gesamtpunktzahl vorher',
    standardPoints: 'Standardpunkte',
    boosterPoints: 'Punti booster',
    allPoints: 'Tutti i punti',
    pointsByUser: 'Scarica punti per utente',
    pointsByGroup: 'Scarica punti per gruppo',
    loginAs: 'Accedi come utente',
    positionX: 'posizione x',
    positionY: 'posizione y',
    textSize: 'Dimensione del testo',
    text: 'testo',
    publish: 'Pubblicare',
    certificate: 'certificato',
    event: 'Evento',
    remove_cs_number: 'Sei sicuro di voler eliminare il numero cliente?',
    remove_order: "Sei sicuro di voler eliminare l'ordine?",
    is_internal: "L'utente è interno?",
    selectAddressBtn: "scegli l'indirizzo",
    currentBasket: 'Carrello attuale',
    loginEmail: 'Registrazione',
    open_new_tab: 'Apri in una nuova scheda?',
    file: 'file',
    company_name: 'azienda',
    partner_name: 'Referente',
    dealer: 'Rivenditore',
    boosterImport: {
      label: 'File CSV.',
    },
    created_at: 'Creato',
    full_text_search: 'Ricerca nel testo completo',
    logs: 'Registri',
    activity: 'attività',
    booster_create: 'Crea booster',
    booster_wholesaler: 'Grossista',
    booster_products: 'Nomi dei prodotti',
    booster_customers: 'Numeri dei clienti',
    booster_saison: 'stagione',
    booster_name: 'Cognome',
    booster_factor: 'fattore',
    booster_from: 'tempo di esecuzione di',
    booster_to: 'Durata fino a',
    booster_tires: 'Dimensioni dei pneumatici',
    booster_ipcs: 'IPC',
    select: 'Si prega di scegliere',
    booking: 'biglietteria',
    credit: 'Credito',
    deduction: 'Deduzione',
    preview: 'anteprima',
    subject: 'Per quanto riguarda',
    customerNumber: 'Numero cliente',
    search: 'Cercare',
    customer_nr_name: 'Numero/nome del cliente',
    selectAll: 'seleziona tutto',
    import: 'Importare',
    articleNumber: 'codice articolo',
    inform_user: 'per informare i clienti',
    leaveComment: 'Lascia un commento qui',
    user_new: 'Nuovo utente',
    postalcode: 'Codice postale',
    city: 'posizionet',
    residence: 'È residenza',
    country: 'Paese',
    street: 'Via e numero civico',
    salutation: 'Saluto',
    suffix: 'Supplemento indirizzo',
    emailVerified: 'Email verificata il',
    registerAt: 'Registrato il',
    phone: 'Telefono',
    optinAt: 'Accetta',
    fax: 'Fax',
    date: 'Data',
    optinToken: 'Token di adesione',
    language: 'Lingua',
    company: 'azienda',
    forename: 'Nome di battesimo',
    lastname: 'Cognome',
    title: 'titolo',
    remove: 'Eliminare',
    actions: 'Azioni',
    userData: 'Dati utente',
    profile: 'Profilo',
    addresses: 'Indirizzi',
    address: 'indirizzo',
    show0Products: 'Visualizza quando inventario = 0',
    notAvailable: 'Attualmente non disponibile al momento',
    limitless: 'Senza limiti',
    isDefault: 'Predefinito',
    data: 'Dati',
    importText: 'Seleziona un file da importare:',
    upload: 'Caricamento',
    users: 'utente',
    permission: 'Giusto',
    save: 'Salva',
    downloadOrderStats: 'Scarica le statistiche',
    downloadProductStats: 'Scarica le statistiche dei prodotti',
    category: 'categoria',
    order: 'Ordine',
    permissions: 'Giusto',
    options: 'Opzioni',
    attributes: 'Attributi',
    add_attribute: 'Aggiungi attributi',
    login: 'Aggiungi attributi',
    logout: 'Disconnettersi',
    email: 'E-Mail',
    username: 'utente',
    filter: 'Filtro',
    name: 'Cognome',
    points: 'Punti',
    sku: 'SKU',
    count: 'Numero',
    action: 'azione',
    active: 'Attivo',
    type: 'Tipo',
    status: 'Condizione',
    main: 'Prodotto principale',
    vendor: 'Produttore',
    supplier: 'fornitore',
    variant: 'Variante del prodotto',
    description: 'Descrizione',
    short_description: 'Breve descrizione',
    upload_text: "Caricamento dell'immagine del prodotto",
    product_new: 'Nuovo prodotto unico',
    product_new_variants: 'Prodotto nuova variante',
    training_new: 'Nuova formazione',
    btraining_new: 'Nuova formazione BS',
    images: 'Immagini',
    roles: 'rotolo',
    search_products: 'Ricerca prodotto',
    search_orders: 'Ordini di ricerca',
    search_type: 'Tipo di ricerca',
    hunter_new: 'Nuovo cacciatore',
    first_name: 'Nome di battesimo',
    last_name: 'Cognome',
    copy: 'copia',
    add_empty_variant: 'Aggiungi variante vuota',
    menu: {
      shopLimits: 'Shop limits',
      default_banners: 'Banner predefiniti',
      import_adex: 'Importazione di Adex',
      adex: 'Adex',
      logs: 'Registri',
      activities: 'attività',
      booster_setup: 'Imposta i booster',
      booster_list: 'elenco',
      customer: 'Clienti',
      'users-roles': 'Gestione utenti',
      roles: 'rotolo',
      booster: 'Ripetitore',
      cheques: 'Controlli e detrazioni',
      points: 'Gestione punti',
      users: 'utente',
      users_list: 'Lista degli utenti',
      user_new: 'Nuovo utente',
      users_deleted: 'Utenti eliminati',
      perms: 'Giusto',
      'categorie di prodotti': 'Negozio di premi',
      categories: 'Categorie',
      categories_list: 'Elenco delle categorie',
      category_new: 'Nuova categoria',
      orders: 'Ordini',
      products: 'Prodotti',
      products_list: 'Elenco prodotti',
      product_new: 'Nuovo prodotto unico',
      product_new_variants: 'Prodotto nuova variante',
      attributes: 'Attributi',
      attributes_list: 'Elenco degli attributi',
      attribute_new: 'Nuovi attributi',
      options: 'Opzioni',
      options_list: 'Elenco delle opzioni',
      options_new: 'Nuova opzione',
      vendors: 'Produttore',
      vendors_list: 'Elenco dei produttori',
      vendor_new: 'Nuovo produttore',
      suppliers: 'fornitori',
      suppliers_list: 'Elenco fornitori',
      supplier_new: 'Nuovo fornitore',
      sliders: 'Cursore principale del teaser',
      sliders_list: 'elenco',
      slider_new: 'Nuovo cursore principale del teaser',
      featured: 'Prodotti teaser',
      commands: 'Comandi',
      command_ipc: 'IPC',
      command_users: 'Importa utenti',
      'trainings-schulungen': 'Formazione e istruzione',
      trainings: 'Formazione in linea',
      trainings_list: 'elenco',
      training_new: 'Aggiungere nuova',
      btrainings: 'Formazione da sballo',
      btrainings_list: 'elenco',
      btraining_new: 'Aggiungere nuova',
      management: 'Gestione dei contenuti',
      textpages: 'Pagine di testo',
      textpages_list: 'elenco',
      text_page_new: 'Aggiungere nuova',
      settings: 'Responsabile delle notizie',
      messages: 'Crea messaggi',
      messages_list: 'elenco',
      messages_new: 'Aggiungere nuova',
      threads: 'Configura le email automatiche',
      requests: 'Richieste degli utenti',
      product_trainings_request: 'Formazione in linea',
      bridgestone_courses_request: 'Formazione da sballo',
      external_trainings_request: 'Formazione esterna',
      hunter_request: 'Cacciatore',
      investments_request: 'Sostegno agli investimenti',
      news_feed: 'notizia',
      news_feed_list: 'elenco',
      news_feed_new: 'Aggiungere nuova',
      salespromotions: 'Promozione delle vendite',
      imported_products: 'Prodotti importati',
      hunter_list: 'elenco',
      hunters: 'Cacciatore',
      hunter_new: 'Nuovo cacciatore',
      groups: 'gruppi',
      groups_list: 'Elenco dei gruppi',
      group_new: 'Nuovo gruppo',
      history: 'Cronologia degli accessi',
      history_list: 'Cronologia degli accessi',
      prices: 'Pricat',
      prices_list: 'elenco',
      price_new: 'nuovo Pricat',
      price_categories: 'Listino prezzi',
      price_categories_list: 'elenco',
      price_categories_new: 'Nuovi listini prezzi',
      inactive_users: 'Nuove registrazioni<br/>rilascio',
      customer_numbers_to_approve: 'Nuove immatricolazioni<br/>senza numero',
      complaints: 'Modulo di reclamo',
      orders_list: 'elenco',
      orders_new: 'Nuovi ordini',
      points_list: 'Elenco punti',
      kickback_list: 'Commissione di contraccolpo',
      booster_preview: 'anteprima',
      frontend_menu: 'Menù frontend',
      frontend_menu_list: 'elenco',
      frontend_menu_new: 'Nuovo',
      mediapages: 'Pacchetti multimediali',
      mediapages_list: 'elenco',
      media_page_new: 'Nuovo',
      uberall_request: 'Ovunque',
      history_hour: 'Tempi di registrazione',
      wholesaler_management: 'Gestione grossisti',
      mediapages_file_list: 'elenco dei file',
      vouchers: 'Vouchers',
      vouchers_list: 'List',
      vouchers_new: 'New',
      brochure_prices_list: 'Liste',
      brochure_prices: 'Broschüren & Preislisten',
      brochure_prices_new: 'Neu',
      main_boxes: 'Teaser Boxes',
      main_boxes_list: 'List',
      main_box: 'Edit',
      main_boxes_new: 'New',
    },
    breadcrumb: {
      shopLimits: 'Shop limits',
      default_banners: 'Banner predefiniti',
      import_adex: 'Importazione di Adex',
      adex: 'Adex',
      activities: 'attività',
      booster_setup: 'Imposta i booster',
      booster_list: 'elenco',
      customer: 'Clienti',
      booster: 'Ripetitore',
      cheques: 'Controlli e detrazioni',
      user_edit: 'Modifica utente',
      users: 'utente',
      user: 'utente',
      vendors: 'Produttore',
      vendor_new: 'Nuovo produttore',
      suppliers: 'fornitori',
      supplier_new: 'nuovo fornitore',
      home: 'Casa',
      perms: 'Giusto',
      roles: 'rotolo',
      roles_list: 'elenco',
      dashboard: 'Pannello di controllo',
      orders: 'Ordini',
      orders_edit: 'Modifica il tuo ordine',
      orders_view: 'Ordine',
      orders_list: 'elenco',
      salespromotions: 'Promozione delle vendite',
      salespromotions_list: 'elenco',
      salespromotions_view: 'visualizzazione',
      categories: 'Categorie',
      categories_list: 'elenco',
      role: 'ruolo',
      category: 'Categorie',
      products: 'Prodotti',
      products_list: 'elenco',
      product: 'Prodotto',
      product_category: 'Prodotti di categoria',
      product_new: 'Nuovo prodotto unico',
      product_new_variants: 'Prodotto nuova variante',
      product_variants: 'Prodotto variante',
      attributes: 'Attributi',
      attributes_list: 'elenco',
      attribute: 'attributo',
      category_new: 'Nuova categoria',
      attribute_edit: 'Modifica attributo',
      options_new: 'Nuova opzione',
      options: 'Opzioni',
      options_list: 'elenco',
      options_edit: 'Modifica opzione',
      userEdit: 'Modifica utente',
      users_list: 'elenco',
      users_deleted: 'Utenti eliminati',
      vendor_edit: 'Modifica produttore',
      vendors_list: 'elenco',
      supplier_edit: 'Modifica fornitore',
      suppliers_list: 'Elenco fornitori',
      user_new: 'Nuovo utente',
      trainings: 'Formazione',
      trainings_list: 'elenco',
      btrainings: 'Formazione in linea',
      btrainings_list: 'elenco',
      btraining: 'Formazione in linea',
      training_new: 'Nuova formazione',
      btraining_new: 'Nuova formazione BS',
      training_edit: 'Modifica formazione',
      training: 'formazione',
      command_ipc: 'IPC',
      sliders: 'Cursore principale del teaser',
      sliders_list: 'elenco',
      slider_new: 'Nuovo cursore principale del teaser',
      slider: 'Modifica il dispositivo di scorrimento del teaser principale',
      command_users: 'Importa utenti',
      textpages: 'Pagine di testo',
      text_page_new: 'Nuova pagina di testo',
      textpage: 'Pagine di testo',
      textpages_list: 'elenco',
      messages: 'Notizia',
      messages_list: 'elenco',
      messages_new: 'Nuovo messaggio',
      messages_edit: 'modificare il messaggio',
      threads: 'Per quanto riguarda',
      featured: 'Prodotti teaser',
      attribute_new: 'Nuovo attributo',
      product_trainings_request: 'Richieste di formazione online',
      bridgestone_courses_request: 'Richieste di formazione Bridgestone',
      external_trainings_request: 'Richieste di formazione esterna',
      hunter_request: 'Cacciatore',
      investments_request: 'Sostegno agli investimenti',
      news_feed: 'notizia',
      news_feed_list: 'elenco',
      news_feed_new: 'Nuovo feed di notizie',
      news_feed_edit: 'Modifica il feed di notizie',
      news_feed_slider: 'Nuovo dispositivo di scorrimento del feed di notizie',
      imported_products: 'Prodotti importati',
      hunter_list: 'elenco',
      hunters: 'Cacciatore',
      hunter_new: 'Nuovo cacciatore',
      hunter_edit: 'Modifica Cacciatore',
      groups: 'gruppi',
      groups_list: 'Elenco dei gruppi',
      group_new: 'Nuovo gruppo',
      group_edit: 'Modifica gruppo',
      history: 'Cronologia degli accessi',
      history_list: 'Cronologia degli accessi',
      history_view: "Cronologia di accesso dell'utente",
      prices: 'Pricat',
      prices_list: 'elenco',
      price_new: 'nuovo Pricat',
      price: 'Modifica Pricat',
      price_categories: 'Listino prezzi',
      price_categories_list: 'elenco',
      price_categories_new: 'Nuovi listini prezzi',
      price_category: 'Listino prezzi',
      inactive_users: 'Rilascia nuove registrazioni',
      inactive_users_list: 'elenco',
      customer_numbers_to_approve: 'Nuove immatricolazioni senza numero',
      complaints: 'Modulo di reclamo',
      complaints_list: 'elenco',
      complaints_view: 'Rimostranza',
      orders_new: 'Nuovi ordini',
      points_list: 'Elenco punti',
      kickback_list: 'Commissione di contraccolpo',
      booster_preview: 'anteprima',
      frontend_menu: 'Menù frontend',
      frontend_menu_list: 'elenco',
      frontend_menu_new: 'Nuovo',
      mediapages: 'Pacchetti multimediali',
      mediapages_list: 'elenco',
      media_page_new: 'Nuovo',
      mediapage: 'Modificare',
      uberall_request: 'Ovunque',
      history_hour: 'Tempi di registrazione',
      wholesaler_management: 'Gestione grossisti',
      mediapages_file_list: 'elenco dei file',
      vouchers: 'Vouchers',
      vouchers_list: 'List',
      vouchers_new: 'New',
      brochure_prices_list: 'Liste',
      brochure_prices: 'Broschüren & Preislisten',
      brochure_prices_new: 'Neu',
      main_boxes: 'Teaser Boxes',
      main_boxes_list: 'List',
      main_box: 'Edit',
      main_boxes_new: 'New',
    },
    orders: {
      view: 'Ordine',
      new: 'Nuovo ordine',
      list: 'Ordini',
      edit: 'Modifica il tuo ordine',
      number: "Numero d'ordine",
      date: "Data dell'ordine",
      state: "lo stato dell'ordine",
      status: {
        created: 'Creato',
        confirm_order: "confermare l'ordine",
        ready_for_shipment: 'Pronto a spedire',
        rejected: 'Respinto',
        processed: 'Elaborato',
        shipped: 'Inviato',
        returned: 'Restituito',
        finished: 'Pronto',
      },
    },
    salespromotions: {
      view: 'Promozione delle vendite',
      list: 'Promozioni di vendita',
      premium: 'GUIDA IL NOSTRO MEGLIO',
      guarantee: 'PROTEZIONE DEI PNEUMATICI',
      bonusCampaign: 'COLPI DI PNEUMATICI',
      advantage: 'PROMOZIONE VANTAGGIOSA',
    },
    countries: {
      de: 'Germania',
      at: 'Austria',
      ch: 'Svizzera',
      nl: 'Olanda',
      fr: 'Francia',
    },
    messages: {
      list: 'Notizia',
      new: 'Nuovo messaggio',
      edit: 'modificare il messaggio',
      title: 'titolo',
      content: 'Contenuti',
      empty: 'Nessuna novità',
      direct: 'Messaggio diretto',
      toDealers: 'Ai rivenditori',
      sendEmail: 'Invia una email',
      selectWholesalers: 'Seleziona grossista',
      directWholesaler: 'dal grossista al rivenditore',
      saveAndSend: 'Salva e invia',
      users: 'Seleziona utente (min. 3 caratteri)',
      sent: 'Pubblicato',
      externalTrainingPoints:
        'Punti di supporto per formazione/investimenti esterni',
      externalTrainingBank:
        'Titolari di conti di supporto per formazione/investimenti esterni',
      externalTrainingIBAN:
        'Formazione esterna/supporto agli investimenti IBAN',
      onlineTrainingTitle: 'Formazione online - Titolo',
      onlineTrainingPoints: 'Formazione online - punti',
      hunterTitle: 'Cacciatore - titolo',
      hunterPoints: 'Cacciatore - punti',
      hunterPrice: 'Cacciatore - prezzo',
      bTrainingTitle: 'Corsi di formazione Bridgestone - titolo',
      bTrainingPoints: 'Formazione BS - Punti',
      pointsProfileComplete: 'Punti: profilo completato',
      orderProducts: "Prodotti nell'ordine",
    },
    messages_alert: {
      title: 'Cancella il messaggio',
      desc: 'Sei sicuro di voler eliminare il messaggio?',
    },
    groups_alert: {
      title: 'Elimina gruppo',
      desc: 'Sei sicuro di voler eliminare il gruppo?',
    },
    vendors_alert: {
      title: 'Elimina produttore',
      desc: 'Vuoi davvero eliminare il produttore?',
    },
    suppliers_alert: {
      title: 'Elimina fornitore',
      desc: 'Vuoi davvero eliminare il fornitore?',
    },
    threads: {
      title: 'Per quanto riguarda',
      choose: 'Seleziona il messaggio',
      order: "Seleziona l'oggetto degli ordini",
      salespromotions: "Seleziona l'oggetto per la promozione delle vendite",
      externalTraining: 'Seleziona la materia per la formazione esterna',
      investment: 'Oggetto del sostegno agli investimenti',
      onlineTraining: 'Oggetto della formazione online',
      hunter: 'Oggetto per Hunter',
      bTraining: 'Oggetto per la formazione Bridgestone',
      profileComplete: 'Oggetto per il completamento del profilo',
      activeUser: 'Messaggio utente attivo',
    },
    userGroup: {
      name: 'Nome del gruppo',
      gka: 'GKA',
    },
    role: 'ruolo',
    user: 'utente',
    vat: 'codice fiscale',
    categories: 'Categorie',
    count_users: 'numero di utenti',
    product: 'Prodotto',
    count_perms: 'Numero di diritti',
    count_products: 'Numero di prodotti',
    count_options: 'Numero di opzioni',
    attribute_new: 'Nuovo attributo',
    category_new: 'Nuova categoria',
    category_title: 'Titolo della categoria',
    category_desc: 'Descrizione della categoria',
    category_image: 'Immagine di categoria',
    category_link: 'Collegamento di categoria',
    category_alert: {
      title: 'Elimina categoria',
      desc: 'Sei sicuro di voler eliminare la categoria?',
    },
    product_alert: {
      title: 'Elimina prodotto',
      desc: 'Sei sicuro di voler eliminare il prodotto?',
    },
    cancel: 'Annulla',
    confirm: 'Confermare',
    options_new: 'Nuova opzione',
    subcategory: 'Sottocategoria',
    uploadText: "per caricare un'immagin",
    startImport: "Avvia l'importazione",
    importStarted: "L'importazione è iniziata",
    connection: 'Connessione',
    variants: 'varianti',
    add_variant: 'Aggiungi variante',
    vendor_new: 'Nuovo produttore',
    training_edit: 'Modifica formazione',
    vendors: 'Produttore',
    supplier_new: 'Nuovo fornitore',
    suppliers: 'fornitori',
    training: 'formazione',
    commands: {
      ipc: 'Importatore IPC',
      users: 'Importa utenti',
    },
    userImport: {
      label: 'Solo tipo XLSX',
    },
    questions: 'Domande',
    correctAnswer: 'Risposta correttat',
    validation: {
      required: 'Il campo è obbligatiorio',
    },
    edit_question: 'Modifica domande',
    edit_btraining_Detail: 'Modifica i dettagli della formazione Bridgestone',
    video_url: 'URL del video',
    save_new_question: 'Salva nuova domanda',
    reward: 'Illustrazione del premio - non necessaria',
    poster: 'Immagine del manifesto',
    season: 'stagione',
    answers: 'Risposta',
    training_details: 'Dettagli della formazione',
    extras: 'Ulteriori informazioni',
    duration: 'Periodo di tempo',
    price: 'Prezzo',
    min_participant: 'Numero minimo di partecipanti',
    max_participant: 'Numero massimo di partecipanti',
    choose_trainingType: 'Seleziona il tipo di allenamento',
    update: 'Aggiornare',
    cost_type: 'Tipo di costo',
    save_detail: 'Salva i dettagli',
    slider_new: 'Nuovo cursore principale del teaser',
    sliders: 'Cursore principale del teaser',
    groups: 'gruppi',
    slider: 'Modifica il dispositivo di scorrimento del teaser principale',
    choose_showHideText: 'Mostra/nascondi testo',
    text_page_new: 'Nuova pagina di testo',
    text_pages: 'Pagine di testo',
    text_page: 'Pagina di testo',
    featured: {
      title: 'Prodotti teaser',
      bestseller: 'Il più venduto',
      seasonal: 'di stagione',
      affordable: 'Quasi lì',
      autoBestsellers: 'Mostra i bestseller calcolati automaticamente',
      affordableOffsetLabel: 'Massima compensazione del prezzo',
      affordableLimitLabel: 'Mostra limite prodotto',
    },
    seasons: {
      all: 'Tutte le stagioni',
      summer: 'Estate',
      winter: 'inverno',
      general: 'Generalmente',
    },
    addAddress: 'Aggiungi indirizzo',
    administration: 'Amministrazione',
    password: "parola d'ordine",
    toastMessages: {
      success: "L'operazione è stata completata con successo",
      error: 'Errore imprevisto, riprova più tardi',
      invalid: 'Valori non validi nel modulo',
      done: 'Completato!',
      requestProcessed:
        'La richiesta è in fase di elaborazione. Un momento per favore...',
      noQuestions: 'La formazione deve contenere almeno 1 domanda',
      pointsAdded: 'Prenotazione effettuata',
      selectUser: "Seleziona un utente dall'elenco",
      selectOneField: 'Seleziona un campo',
      pointRemoveSoon: 'I punti verranno eliminati a breve',
      dataLoading: 'I dati sono in caricamento, attendi',
    },
    stats: {
      users_list: 'numero di utenti',
      external_trainings_request: 'Formazione esterna',
      product_trainings_request: 'Formazione in linea',
      bridgestone_courses_request: 'Allenamento Bridgestone',
      hunter_request: 'Cacciatore',
      investments_request: 'Sostegno agli investimenti',
      orders_list: 'Ordini',
      history_list: 'Cronologia degli accessi',
      salespromotions_list: 'Promozione delle vendite',
      inactive_users: 'Rilascia nuove registrazioni',
      complaints_list: 'Modulo di reclamo',
      points_list: 'Elenco punti',
      kickback_list: 'Commissione di contraccolpo',
      history_hour: 'Tempi di registrazione',
      tyre_list: 'Pneumatici',
    },
    product_trainings_request: 'Richieste di formazione online',
    bridgestone_courses_request: 'Richieste di formazione Bridgestone',
    attempt_number: 'Numero di prova',
    thirdPartyInvoice: 'Fattura di terzi',
    userInvoice: "Fattura dell'utente",
    bankAccountOwner: "titolare dell'account",
    open: 'Aprire',
    url: 'URL',
    url_text: "Testo dell'URL",
    choose_posterSide: 'Pagina immagine: ',
    left: 'Sinistra',
    right: 'Giusto',
    disclaimer: 'Disclaimer',
    supply: {
      history: 'Storia della fornitura',
      quantity: 'Folla',
    },
    copyAsMain: 'Copia come teaser principale',
    news_feed_slider_list:
      "L'elenco dei dispositivi di scorrimento del feed di notizie",
    usersPage: {
      boostersToApprove: "Booster per l'approvazione",
      lastLogin: 'Ultimo accesso',
      customerNumbers: 'Numero cliente',
      branches: 'rami',
      balance: 'saldo bancario',
      orders: 'Ordini',
      groups: 'gruppi',
      notes: 'Osservazioni',
      salesStaff: 'Vendite esterne',
      parentBranch: 'business principale',
      childBranch: 'ramo',
      addWholesaler: 'Aggiungi grossista',
      addBranch: 'Aggiungi ramo',
      tooltip:
        'Per aggiungere una filiale è necessario salvare il numero cliente del negozio principale',
      tooltipRmCnLocal: 'Rimuovere il numero cliente locale',
      tooltipRmCnDB: 'Rimuovere il numero cliente per il database',
      statement: 'estratto conto',
      bank: {
        date: 'Data',
        supplySourceReason: 'Fonte/motivo',
        customerNumber: 'Kdnr.',
        article: 'Articolo',
        brand: 'marca',
        product: 'Prodotto',
        type: 'Tipo',
        inch: 'Dogana',
        season: 'stagione',
        amount: 'Folla',
        otherActions: 'Altra azione o causa',
        totalPoints: 'Punti totali',
        loadMore: 'Caricare altri 20 fatturati',
        seasons: {
          all: 'Tutte le stagioni',
          summer: 'Cicalino',
          winter: 'inverno',
          general: 'generale',
        },
      },
      advanced: 'Avanzate',
      deleteUser: "eliminare l'utente",
      deleteUserConfirm: "Sei sicuro di voler eliminare l'utente?",
      selectRole: 'Seleziona ruolo',
      selectWholesaler: 'Seleziona grossista',
    },
    recoverUser: 'Riporta indietro gli utenti',
    pieces_per_set: 'Unità di imballaggio',
    new: 'Nuovo',
    all: 'Tutto',
    showAddress: 'Mostra indirizzo',
    showGroup: 'Mostra GKA e Bl-Alt',
    showPoints: 'Visualizza i punteggi',
    showKickbacks: 'Mostra la commissione di tangente',
    external_sales: 'Vendite esterne',
    login_from: 'Ultimo accesso (da)',
    login_to: 'Ultimo accesso (fino al)',
    check: 'Controllo',
    reject: 'Rifiutare',
    reason: 'Motivo',
    active_by: 'Attivato da',
    sharePassword: 'Condividi questa password con il nuovo amministratore',
    activeBtn: 'Attivare ora',
    newUserVerify: 'Verifica della nuova iscrizione',
    complaintForm: {
      sections: {
        general: {
          title: 'generalmente',
          ordinance: 'Il concetto di ordine del rivenditore',
          generalEmail: 'Il tuo indirizzo di posta elettronica',
          country: 'Paese',
          pickupLocation: 'Posto di raccolta',
        },
        regulator: {
          title: 'indirizzo gommista - regolatore',
        },
        vehicleDetails: {
          title: 'Informazioni sul veicolo',
          vehicleTyres: 'Veicolo/Pneumatici',
          vehicleManufacturer: 'Produttore di veicoli',
          vehicleModelType:
            "Modello del veicolo e designazione del tipo secondo l'omologazione",
          firstRegistration: 'Registrazione iniziale',
          enginePower: 'Potenza motore (KW es. 150)',
          originalEquipment: 'Equipaggiamento originale',
          tireMilageHours:
            'Chilometraggio pneumatici (km) / ore di funzionamento',
          milageOrHours: 'Km o ore (es. 2000)',
        },
        tyre: {
          tirePosition: 'Posizione dei pneumatici',
          tireSizeExample: 'Misura pneumatici (es. 215/60/16)',
          speedLoadIndex: 'Indice di velocità/carico',
          profileDesignation: 'Denominazione del profilo incl. specifica',
          dot: 'PUNTO',
          truckSerialNo: 'Numero di serie. (solo per pneumatici camion)',
          complaintReason: 'Motivo del reclamo',
          isTyreReplaced: 'I pneumatici sono già stati sostituiti?',
          ifYesTyre: 'Se sì, marca degli pneumatici sostitutivi',
          clerkName: "Nome dell'impiegato",
          complaintReasonText: 'Testo del motivo del reclamo',
        },
      },
    },
    yesText: 'si',
    noText: 'no',
    tyre: 'Pneumatici',
    buttons: {
      close: 'Vicino',
      placeOrder: 'Ordine vincolante',
      download: 'Scaricamento',
      approve: 'Permettere',
      deleteOrder: 'Elimina ordine',
      archive: 'Archivio',
      acceptAll: 'Accettare tutti',
      advanceSearch: 'Ricerca Avanzata',
      addMenu: 'Aggiungi menù',
      setMaintenance: 'Imposta la manutenzione',
      hideMaintenance: 'Nascondi manutenzione',
      deactivate: 'Disattivare',
      importSelected: 'Importa selezionato',
      clearFilters: 'Filter zurücksetzen',
      toSearch: 'Search',
      delete: 'Delete',
    },
    others: 'Altro',
    archive_training: 'Sei sicuro di voler archiviare questa formazione?',
    delete_menu: 'Vuoi eliminare la voce di menu?',
    no_entries: 'Nessuna voce',
    external_url: 'URL esterno',
    incomplete_address: "L'indirizzo è incompleto. si prega di controllare",
    request_from: 'richiesta da',
    let_see_points: "Consenti all'utente di vedere i punti di marketing",
    let_see_kickback:
      "Consenti all'utente di vedere la commissione di tangente",
    major: 'Grep',
    media_category: 'Categoria “Media”',
    dragHere: 'Rilascia i file qui...',
    addEmbbeded: 'Aggiungi codice incorporato',
    canReceivePoints: 'Può ottenere punti',
    canUsePoints: 'Può usare i punti',
    wholesaler_management: {
      modal: {
        description:
          'Quali dati può vedere il grossista nei dettagli del rivenditore?',
      },
    },
    personalData: 'Dati personali',
    operatingData: 'Dati operativi',
    orderDelivery: 'indirizzo di consegna',
    diffDeliveryAddres: 'aggiungi un indirizzo di consegna',
    current_tyres_amount: 'Importo del pneumatico',
    current_points: 'Punto',
    current_kickbacks: 'Kick-back',
    change_role_info:
      "La modifica di un ruolo nel sistema <b>Bridgestone PLUS-System</b> elimina le connessioni associate a un determinato ruolo, ad es. punti, numeri cliente, connessioni tra un processore Bridgestone e un rivenditore, ordini, ecc. vengono eliminati. Sei sicuro di voler apportare questa modifica perché non c'è modo di tornare indietro?",
    firstRegistration: 'Prima registrazione',
    mediaCategories: {
      products: 'Produkte',
      brand_and_solutions: 'Marke & Lösungen',
      season_and_promotions: 'Saison & Aktionen',
      format: 'Format',
      cars_and_vans: 'Pkw & Transporter',
      truck_and_buses: 'Lkw & Bus',
      motorcycle: 'Motorrad',
      agriculture: 'Landwirtschaft',
      construction_machinery: 'Baumaschinen',
      bridgestone: 'Bridgestone',
      firestone: 'Firestone',
      bandag: 'Bandag',
      bleetcare: 'Fleetcare',
      webfleet: 'Webfleet',
      summer: 'Sommer',
      winter: 'Winter',
      all_season: 'All Season',
      promotions: 'Aktionen',
      video_in_store: 'Video (Instore)',
      video_online: 'Video (Online)',
      banner_website: 'Banner (Webseite)',
      banner_social_media: 'Banner (Social Media)',
      logos: 'Logos',
      b_drive: 'B-drive',
      media_library: 'Media library',
      media_packages: 'Media packages',
    },
    fileType: {
      label: 'File type',
      video: 'Video',
      image: 'Image',
      embeded: 'Embeded',
    },
    deleteMediaModal: {
      title: 'Delete media collection',
      description:
        "Do you confirm delete file's collection? It will delete all connect files.",
    },
    deleteMediaFileModal: {
      title: 'Delete file from collection',
      description: 'Do you want to you delete file from this collection?',
    },
    languages: {
      de: 'Tedesco',
      ch: 'Tedesco',
      fr: 'Francese',
      en: 'Inglese',
      nl: 'Olandese',
      it: 'Italiano',
    },
    colleague: 'collega',
    tradeInDate: 'data di permuta',
    vouchers: {
      points: 'Numero di punti per buono',
      amount: 'Numero di voucher',
    },
    selectText: 'Selezionare',
    brochureCategories: {
      products: 'Prodotti',
      brand_and_solutions: 'Marchio e soluzioni',
      season_and_promotions: 'Stagione e promozioni',
      format: 'Formato',
      cars_and_vans: 'Auto e veicoli commerciali',
      truck_and_buses: 'Camion e autobus',
      motorcycle: 'Motocicletta',
      agriculture: 'Agricoltura',
      construction_machinery: 'Macchine da costruzione',
      bridgestone: 'Bridgestone',
      firestone: 'Firestone',
      bandag: 'Bandag',
      bleetcare: 'Fleetcare',
      webfleet: 'Webfleet',
      material: 'Materiali',
      brochures: 'Brochure',
      price_list: 'Listino prezzi',
      materials: 'Materiali',
    },
    deleteMainBox: {
      title: 'Elimina la casella principale',
      description: 'Sei sicuro di voler eliminare questo elemento?',
    },
  },
}
