const apps = [
  {
    path: '',
    name: 'apps_list',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/apps/List.vue'),
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['fr', 'pt', 'es'],
      allowedTenant: ['liteVersion'],
    },
  },
  {
    path: 'new',
    name: 'app_new',
    showInMenu: false,
    components: {
      content: () => import('@/views/pages/apps/New.vue'),
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['fr', 'pt', 'es'],
      allowedTenant: ['liteVersion'],
    },
  },
  {
    path: ':id',
    name: 'app',
    components: {
      content: () => import('@/views/pages/apps/Edit.vue'),
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['fr', 'pt', 'es'],
      allowedTenant: ['liteVersion'],
    },
  },
]

export default apps
