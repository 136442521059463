<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid class="flex justify-content-between" to="/">
      <CButton @click="setMenu" type="button">
        <CIcon icon="cil-menu"></CIcon>
      </CButton>
      <img :src="require('@/assets/bridgestone_logo.svg')" />
      <CButtonGroup role="group" aria-label="Basic example">
        <CButton color="primary" type="button" @click="setUpLocale('nl')" v-if="isNetherland"><img class="flag"
            :src="require('@/assets/images/flags/nl.png')" /></CButton>
        <CButton color="primary" type="button" @click="setUpLocale('en')" v-if="isNetherland"><img class="flag"
            :src="require('@/assets/images/flags/en.png')" /></CButton>
        <CButton color="primary" type="button" :title="$t('message.buttons.setMaintenance')"
          v-if="!maintenance && isAdmin" @click="createMaintenance()" :disabled="loading">
          {{ $t('message.buttons.setMaintenance') }}
        </CButton>
        <CButton color="danger" type="button" :title="$t('message.buttons.hideMaintenance')"
          v-else-if="maintenance && isAdmin" @click="deleteMaintenance()" :disabled="loading">
          {{ $t('message.buttons.hideMaintenance') }}
        </CButton>
        <CButton @click="logout" type="button" :title="$t('message.logout')">
          <CIcon icon="cil-account-logout"></CIcon>
        </CButton>
      </CButtonGroup>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script setup>
import AppBreadcrumb from './AppBreadcrumb'
import { UserService } from '@/services/user.service'
import { AppService } from '@/services/app.service'
import store from '@/store'
import { ref, watch, onMounted, computed } from 'vue'

const menuVisible = ref(true)
const maintenance = ref(false)
const logout = async () => await UserService.logout(true)
let loading = computed(() => store.getters.getPending)

const roles = computed(() => store.getters.getAdminRole)
const isAdmin = computed(() => roles.value.find((item) => item.name == 'admin'))

const setMenu = () => {
  menuVisible.value = !menuVisible.value
  store.dispatch('setMenuVisible', menuVisible.value)
}
const lang = localStorage.getItem('lang')
const isNetherland = computed(() => lang === 'nl')

watch(
  () => store.getters.getMenuVisible,
  (newVal) => {
    menuVisible.value = newVal
  },
)

const createMaintenance = async () => {
  await AppService.createMaintenance()
  await fetchMaintenance()
}

const deleteMaintenance = async () => {
  await AppService.deleteMaintenance()
  await fetchMaintenance()
}

onMounted(async () => {
  await fetchMaintenance()
})

const fetchMaintenance = async () => {
  const response = await AppService.maintenance()

  maintenance.value = Object.keys(response.data).length > 0
}

const setUpLocale = (lang) => {
  localStorage.setItem('currentLocale', lang)

  window.location.reload()
}
</script>

<style scoped>
.flag {
  width: 100%;
  max-width: 20px;
}
</style>