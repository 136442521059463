const logs = [
  {
    path: 'logs',
    name: 'activities',
    icon: 'cil-list-high-priority',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/logs/List.vue'),
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['fullVersion', 'liteVersion'],
    },
  },
  {
    path: 'adex',
    name: 'adex',
    icon: 'cil-list-high-priority',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/adex/New.vue'),
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['fullVersion'],
    },
  },
]

export default logs
