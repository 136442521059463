import ApiService from './api.service'
import TokenService from './storage.service'
import router from '@/router'

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

const UserService = {
  async me() {
    try {
      return await ApiService.get('auth/admin_me')
    } catch (e) {
      return e.response
    }
  },

  async search(search) {
    try {
      return await ApiService.post('customers/search', search)
    } catch (e) {
      return e.response
    }
  },

  async login(username, password, token = null, productId = null) {
    const data = {
      username: username,
      password: password,
      token: token,
      productId: productId,
    }

    try {
      await ApiService.csrfCookie()
      const response = await ApiService.post('auth/login', data)

      TokenService.saveToken(response.data.data.token)
      TokenService.saveAdminId(response.data.data.user.id)
      ApiService.setHeader()

      return response.data.data.token
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail,
      )
    }
  },

  async filter() {
    try {
      return await ApiService.get('filter')
    } catch (error) {
      return error
    }
  },
  async filterSearch(filter) {
    try {
      const queryString = Object.keys(filter)
        .map((key) => key + '=' + filter[key])
        .join('&')
      return await ApiService.get('filter/search?filter=1&' + queryString)
    } catch (error) {
      return error
    }
  },

  async startIpcImport(filename) {
    try {
      return await ApiService.post('commands/ipc', { file: filename })
    } catch (error) {
      return error
    }
  },

  async startUserImport(form) {
    try {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }

      return await ApiService.post('commands/users', form, config)
    } catch (error) {
      return error
    }
  },

  async options() {
    try {
      return await ApiService.get('options')
    } catch (error) {
      return error
    }
  },

  async optionsSelect() {
    try {
      return await ApiService.get('options/select')
    } catch (error) {
      return error
    }
  },

  async getOrderFilters() {
    try {
      return await ApiService.get(`orderFilters`)
    } catch (error) {
      return error
    }
  },

  async getMessages(data = '') {
    try {
      return await ApiService.get(`messages/langs${data}`)
    } catch (error) {
      return error
    }
  },

  async getMessage(id) {
    try {
      return await ApiService.get(`message/${id}/show/langs`)
    } catch (error) {
      return error
    }
  },

  async storeMessage(data) {
    try {
      return await ApiService.post(`messages`, data)
    } catch (error) {
      return error
    }
  },

  async saveMessage(data, id) {
    try {
      return await ApiService.put(`messages/${id}`, data)
    } catch (error) {
      return error
    }
  },

  async addPoints(data) {
    try {
      return await ApiService.put(`users/points/${data.customer}`, {
        points: data.booking == 1 ? data.points : data.points * -1,
        subject: data.subject,
      })
    } catch (error) {
      return error
    }
  },

  async deleteMessage(id) {
    try {
      return await ApiService.delete(`messages/${id}`)
    } catch (error) {
      return error
    }
  },

  async getThreads(data) {
    try {
      return await ApiService.get('threads', data)
    } catch (error) {
      return error
    }
  },

  async createUpdateThreads(data) {
    try {
      return await ApiService.post('threads', data)
    } catch (error) {
      return error
    }
  },

  async vendors() {
    try {
      return await ApiService.get('vendors')
    } catch (error) {
      return error
    }
  },

  async ipcFiles() {
    try {
      return await ApiService.get('commands/ipcFiles')
    } catch (error) {
      return error
    }
  },

  async userFiles() {
    try {
      return await ApiService.get('commands/userFiles')
    } catch (error) {
      return error
    }
  },

  async attributes() {
    try {
      return await ApiService.get('attributes')
    } catch (error) {
      return error
    }
  },

  async vendor(id) {
    try {
      return await ApiService.get('vendors/' + id + '/show/langs')
    } catch (error) {
      return error
    }
  },

  async supplier(id) {
    try {
      return await ApiService.get('suppliers/' + id + '/show/langs')
    } catch (error) {
      return error
    }
  },

  async user(id) {
    try {
      return await ApiService.get('users/' + id + '?with=address,profile')
    } catch (error) {
      return error
    }
  },

  async usersByName(name) {
    try {
      return await ApiService.get(`users/byname/${name}`)
    } catch (error) {
      console.error(error)
    }
  },

  async attribute(id) {
    try {
      return await ApiService.get('attributes/' + id + '/show/langs')
    } catch (error) {
      return error
    }
  },

  async option(id) {
    try {
      return await ApiService.get('options/' + id + '/show/langs')
    } catch (error) {
      return error
    }
  },

  async languages() {
    try {
      return await ApiService.get('languages-list')
    } catch (error) {
      return error
    }
  },

  async saveRole(id, data) {
    try {
      return await ApiService.put('roles/' + id, data)
    } catch (error) {
      return error
    }
  },

  async saveVendor(data, id) {
    try {
      return await ApiService.put('vendors/' + id, data)
    } catch (error) {
      return error
    }
  },

  async saveSupplier(data, id) {
    try {
      return await ApiService.put('suppliers/' + id, data)
    } catch (error) {
      return error
    }
  },

  async saveUser(data, id) {
    try {
      return await ApiService.put('users/' + id, data)
    } catch (error) {
      return error
    }
  },

  async storeUser(data) {
    try {
      return await ApiService.post('users', data)
    } catch (error) {
      return error.response
    }
  },

  async storeVendor(data) {
    try {
      return await ApiService.post('vendors', data)
    } catch (error) {
      return error
    }
  },

  async deleteVendor(id) {
    try {
      return await ApiService.delete(`vendors/${id}`)
    } catch (error) {
      return error
    }
  },

  async saveAttribute(data, id) {
    try {
      return await ApiService.put('attributes/' + id, data)
    } catch (error) {
      return error
    }
  },

  async storeAttribute(data) {
    try {
      return await ApiService.post('attributes', data)
    } catch (error) {
      return error
    }
  },

  async saveOption(data, id) {
    try {
      return await ApiService.put('options/' + id, data)
    } catch (error) {
      return error
    }
  },

  async storeOption(data) {
    try {
      return await ApiService.post('options', data)
    } catch (error) {
      return error
    }
  },

  async permissions() {
    try {
      return await ApiService.get('permissions')
    } catch (error) {
      return error
    }
  },

  async permissionsForSelect() {
    try {
      return await ApiService.get('permissions/select')
    } catch (error) {
      return error
    }
  },

  async users(page = 1, status = '', showPoints = false) {
    try {
      return await ApiService.get(
        `users?page=${page}&status=${status}&show_points=${showPoints}`,
      )
    } catch (error) {
      return error
    }
  },

  async usersList(page = 1) {
    try {
      return await ApiService.get(`usersList?page=${page}`)
    } catch (error) {
      return error
    }
  },

  async vendorsSelect() {
    try {
      return await ApiService.get('vendors/select')
    } catch (error) {
      return error
    }
  },

  async attributesSelect() {
    try {
      return await ApiService.get('attributes/select')
    } catch (error) {
      return error
    }
  },

  async uploadImage(productId, data) {
    try {
      const headers = {
        'Content-Type': 'multipart/form-data',
      }

      return await ApiService.post('upload/product/' + productId, data, headers)
    } catch (error) {
      return error
    }
  },

  async logout(redirectHomepage) {
    try {
      await ApiService.post('users/logout')
      TokenService.removeToken()
      TokenService.removeRefreshToken()
      ApiService.removeHeader()
      if (redirectHomepage) {
        router.push({ name: 'login' })
      }
    } catch (error) {
      return error
    }
  },

  async newQuestion(data) {
    try {
      return await ApiService.post('questions', data)
    } catch (error) {
      return error
    }
  },

  async questions(id) {
    try {
      return await ApiService.get('questions/' + id)
    } catch (error) {
      return error
    }
  },

  async deleteQuestionById(questionId) {
    try {
      const response = await ApiService.delete('questions/' + questionId)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async updateQuestion(id, data) {
    try {
      return await ApiService.put('questions/' + id, data)
    } catch (error) {
      return error
    }
  },

  async reorderQuestion(data) {
    try {
      return await ApiService.post('questions/reorder', data)
    } catch (error) {
      return error
    }
  },

  async getSettings(slug) {
    try {
      return await ApiService.get(`setting/${slug}`)
    } catch (error) {
      return error.response
    }
  },

  async setSetting(data) {
    try {
      return await ApiService.post('settings', data)
    } catch (error) {
      return error.response
    }
  },

  async saveFeaturedProducts(data) {
    try {
      return await ApiService.post('featured', data)
    } catch (error) {
      return error.response
    }
  },

  async loadFeaturedProducts() {
    try {
      return await ApiService.get('featured')
    } catch (error) {
      return error.response
    }
  },

  async loadThreadsBySlug(slug) {
    try {
      return await ApiService.get(`threads/slug/${slug}`)
    } catch (error) {
      return error.response
    }
  },

  async loadThreadsUsers() {
    try {
      return await ApiService.get(`threadsUsers/all`)
    } catch (error) {
      return error.response
    }
  },

  async loadUsersByThread(threadId) {
    try {
      const response = await ApiService.get(
        `threadsUsers/usersByThread/${threadId}`,
      )
      if (response.status == 200) {
        return response.data
      }
    } catch (error) {
      return error.response
    }
  },

  async loadWholesalersUsers() {
    try {
      const response = await ApiService.get('wholesalers')
      if (response.status == 200) {
        return response.data
      }
    } catch (error) {
      return error.response
    }
  },

  async loadDealersUsersByCustomerNumber(customerNumbers, wholesalerId) {
    try {
      const response = await ApiService.post('dealers-by-cs-nr', {
        customer_numbers: customerNumbers,
        wholesaler_id: wholesalerId,
      })
      if (response.status == 200) {
        return response.data
      }
    } catch (error) {
      return error.response
    }
  },

  async loadDealersUsers() {
    try {
      const response = await ApiService.get('dealers')
      if (response.status == 200) {
        return response.data
      }
    } catch (error) {
      return error.response
    }
  },

  async loadSellerDealersUsers(id) {
    try {
      const response = await ApiService.get(`seller/dealers/${id}`)
      if (response.status == 200) {
        return response.data
      }
    } catch (error) {
      return error.response
    }
  },

  async updateSellerDealersUsers(data, id) {
    try {
      return await ApiService.put(`seller/dealers/${id}`, data)
    } catch (error) {
      return error.response
    }
  },

  async storeAddress(data) {
    try {
      return await ApiService.post('addresses', data)
    } catch (error) {
      return error.response
    }
  },

  async getUserAddresses(id) {
    try {
      return await ApiService.get(`addresses/${id}`)
    } catch (e) {
      console.error(e)
    }
  },

  async saveCustomerNumber(data) {
    try {
      return await ApiService.post('saveCustomerNr', data)
    } catch (error) {
      return error.response
    }
  },

  async loadUserOrders(data, page) {
    try {
      return await ApiService.get(`user/adminIndex/${data}/?page=${page}`)
    } catch (error) {
      return error.response
    }
  },

  async loadBankStatement(id, page) {
    try {
      return await ApiService.get(`user/bank_statement/${page}/?user_id=${id}`)
    } catch (error) {
      return error.response
    }
  },

  async loadAllBankStatement(page) {
    try {
      return await ApiService.get(`points/list/${page}`)
    } catch (error) {
      return error.response
    }
  },

  async loadUserLoginHistory(data, page) {
    try {
      return await ApiService.get(`user/login-history/${data}?page=${page}`)
    } catch (error) {
      return error.response
    }
  },

  async loadLoginHistory(page) {
    try {
      return await ApiService.get(`user/login-history?page=${page}`)
    } catch (error) {
      return error.response
    }
  },

  async deleteUser(id) {
    try {
      return await ApiService.delete(`users/remove/${id}`)
    } catch (error) {
      return error.response
    }
  },

  async deleteStaffUser(id) {
    try {
      return await ApiService.delete(`staff/remove/${id}`)
    } catch (error) {
      return error.response
    }
  },

  async loadDeletedUsers(page) {
    try {
      return await ApiService.get(`users/deleted/${page}`)
    } catch (error) {
      return error.response
    }
  },

  async recoverUser(id) {
    try {
      return await ApiService.patch(`user/recover/${id}`)
    } catch (error) {
      return error.response
    }
  },

  async staffs() {
    try {
      return await ApiService.get('staffs')
    } catch (error) {
      return error
    }
  },

  async addStaff(data) {
    try {
      return await ApiService.post('staffs', data)
    } catch (e) {
      return e.response
    }
  },

  async fetchInactiveUsers(
    data = {
      page: 1,
      orderParams: {
        fieldName: 'created_at',
        orderBy: 'DESC',
      },
    },
  ) {
    try {
      return await ApiService.get(
        `inactive/users?page=${data.page}&show_customer_numbers=true&fieldName=${data.orderParams.fieldName}&order=${data.orderParams.orderBy}`,
      )
    } catch (error) {
      return error
    }
  },

  async activeUser(id) {
    try {
      return await ApiService.patch(`user/active/${id}`)
    } catch (error) {
      return error.response
    }
  },

  async rejectUser(data) {
    try {
      return await ApiService.post(`users/reject`, data)
    } catch (error) {
      return error
    }
  },

  async getActivities(userHashId, page = 1, search = null) {
    try {
      if (search) {
        return await ApiService.get(
          `activities/${userHashId}?page=${page}&search=${search}`,
        )
      } else {
        return await ApiService.get(`activities/${userHashId}?page=${page}`)
      }
    } catch (error) {
      return error
    }
  },

  async getGlobalActivities(page = 1, search = null) {
    try {
      if (search) {
        return await ApiService.get(`activities?page=${page}&search=${search}`)
      } else {
        return await ApiService.get(`activities?page=${page}`)
      }
    } catch (error) {
      return error
    }
  },

  async filterActivity(page = 1, ev, type, users, customer_numbers) {
    try {
      return await ApiService.get(
        `activities?page=${page}&filter=1&events=${ev}&type=${type}&users=${users}&customer_numbers=${customer_numbers}`,
      )
    } catch (error) {
      return error
    }
  },

  async getActivityFilters() {
    try {
      return await ApiService.get(`activity/filters`)
    } catch (error) {
      return error
    }
  },

  async updateEmail(data) {
    try {
      return await ApiService.put(`user/update/${data.user_id}/email`, data)
    } catch (error) {
      return error
    }
  },
  async updateName(data) {
    try {
      return await ApiService.put(`user/update/${data.user_id}/name`, data)
    } catch (error) {
      return error
    }
  },

  async updateField(data) {
    try {
      return await ApiService.put(`user/update/${data.user_id}/field`, data)
    } catch (error) {
      return error
    }
  },

  async fetchCustomerNumbersToApprove(
    data = {
      page: 1,
      orderParams: {
        created_at: 'DESC',
        customer_number: 'ASC',
      },
    },
  ) {
    try {
      return await ApiService.get(
        `user/customer-numbers-to-approve?page=${data.page}&show_customer_numbers=true&created_at=${data.orderParams.created_at}&customer_number=${data.orderParams.customer_number}&status=${data.status}`,
      )
    } catch (error) {
      return error
    }
  },

  async uploadUserImage(data) {
    try {
      return await ApiService.put(`upload/user/${data.user_id}/image`, data)
    } catch (error) {
      return error.response
    }
  },

  async userApps(id) {
    try {
      return await ApiService.get(`user/${id}/apps`)
    } catch (error) {
      return error.response
    }
  },
  async asignUserApp(data) {
    try {
      return await ApiService.post(`user/app`, data)
    } catch (error) {
      return error
    }
  },
}

export { UserService, AuthenticationError }
