import ApiService from './api.service'

const AppService = {
  async createCategory(category) {
    try {
      const response = await ApiService.post('categories', { category })
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async createArea(area) {
    try {
      const response = await ApiService.post('sectors', {
        sector: area.area,
        color: area.color,
      })
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async createPost(data) {
    try {
      const response = await ApiService.post('blog/posts', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      return response
    } catch (error) {
      return error.response.data
    }
  },

  async updateCategory(category) {
    try {
      const response = await ApiService.put('categories/' + category.id, {
        title: category.title,
      })
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async drag(data) {
    try {
      const response = await ApiService.post('layers/employees', data)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async updateArea(area) {
    try {
      const response = await ApiService.put('sectors/' + area.id, {
        name: area.name,
      })
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async updateTag(tag) {
    try {
      const response = await ApiService.put('tags/' + tag.id, { tag: tag.tag })
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async deleteCategory(category) {
    try {
      const response = await ApiService.delete('categories/' + category)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async deleteArea(area) {
    try {
      const response = await ApiService.delete('sectors/' + area)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async deleteTag(tag) {
    try {
      const response = await ApiService.delete('tags/' + tag)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async createTag(tag) {
    try {
      const response = await ApiService.post('tags', { tagname: tag })
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async createShift(data) {
    try {
      const response = await ApiService.post('layers', data)
      return response
    } catch (error) {
      return error.response.data
    }
  },

  async companyCategories() {
    try {
      return await ApiService.get('companies/categories')
    } catch (error) {
      return error
    }
  },

  async companyAreas(withUsers) {
    try {
      const response = await ApiService.get(
        'companies/sectors?withUsers=' + withUsers,
      )
      return response
    } catch (error) {
      return error
    }
  },

  async posts() {
    try {
      const response = await ApiService.get('blog/posts')
      return response
    } catch (error) {
      return error
    }
  },

  async postById(id) {
    try {
      const response = await ApiService.get('blog/posts/' + id)
      return response
    } catch (error) {
      return error
    }
  },

  async companyTags() {
    try {
      return await ApiService.get('companies/tags')
    } catch (error) {
      return error
    }
  },

  async week() {
    try {
      return await ApiService.get('layers/weeks')
    } catch (error) {
      return error
    }
  },

  async companyTagsForPost() {
    try {
      return await ApiService.get('companies/tags/for-post')
    } catch (error) {
      return error
    }
  },

  async getStats() {
    try {
      return await ApiService.get('stats')
    } catch (error) {
      return error
    }
  },

  async maintenance() {
    try {
      return await ApiService.get('maintenance')
    } catch (error) {
      console.log(error)
    }
  },

  async deleteMaintenance() {
    try {
      return await ApiService.delete('admin/maintenance/delete')
    } catch (error) {
      console.log(error)
    }
  },

  async createMaintenance() {
    try {
      return await ApiService.post('admin/maintenance/create')
    } catch (error) {
      console.log(error)
    }
  },

  async createApp(data) {
    try {
      const response = await ApiService.post('app', data)
      return response
    } catch (error) {
      return error.response.data
    }
  },
}

export { AppService }
