import ApiService from './api.service'

const BSAppService = {
  async storeCategory(data) {
    try {
      return await ApiService.post('apps', data)
    } catch (error) {
      return error
    }
  },

  async removeCategory(id) {
    try {
      return await ApiService.delete(`apps/${id}`)
    } catch (error) {
      return error
    }
  },

  async saveApp(data) {
    try {
      return await ApiService.put('app', data)
    } catch (error) {
      return error
    }
  },

  async app(id) {
    try {
      return await ApiService.get(`app/${id}`)
    } catch (error) {
      return error
    }
  },

  async apps() {
    try {
      return await ApiService.get('apps')
    } catch (error) {
      return error
    }
  },
  async updateBussinessUnit(data) {
    try {
      return await ApiService.post('app/business-unit', data)
    } catch (error) {
      return error
    }
  },
  async saveAppDescription(data) {
    try {
      return await ApiService.put('app/description', data)
    } catch (error) {
      return error
    }
  },
}

export { BSAppService }
