// config.js or a similar name
export default {
  URL: process.env.VUE_APP_API_URL,
  LANG: process.env.VUE_APP_LANG,
  TINY_MCE_KEY: process.env.VUE_APP_TINY_MCE_KEY,
  FRONT_END_URL: process.env.VUE_APP_FRONT_END_URL,
};

export const TENANCY_SETTINGS = {
  TENANCY: process.env.VUE_APP_TENANCY === "true",
  TENANCY_COUNTRY: process.env.VUE_APP_TENANCY_COUNTRY,
  TENANCY_LOCAL: process.env.VUE_APP_TENANCY_LOCAL === "true"
}

// Define tenant groups and current tenant
export const TENANT_GROUPS = {
  fullVersion: ['DE', 'NL', 'CH'],
  liteVersion: ['PT', 'FR', 'ES'],
  trainingGroup: ['DE', 'CH'],
  pointsGroup: ['DE', 'CH'],
  requestsGroup: ['DE', 'CH'],
  surveyGroup: ['NL', 'EN'],
  newsFeedGroup: ['DE', 'CH'],
  mediaPagesGroup: ['DE'],
  bannerGroup: ['DE', 'CH', 'NL'],
  pricesGroup: ['DE', 'CH', 'NL'],
  priceCategoryGroup: ['CH', 'NL'],
  brochurePricesGroup: ['DE'],
  mainBoxesGroup: ['DE', 'CH', 'NL','PT', 'FR', 'ES'],
  uberAll: ['DE'],
};

export const currentTenant = 'FR'; // Replace with actual logic to get the current tenant
