const TOKEN_KEY = 'token'
const REFRESH_TOKEN_KEY = 'refresh_token'
const ADMIN_ID = 'adminId'

const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY)
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken)
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY)
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY)
  },

  saveAdminId(id) {
    localStorage.setItem(ADMIN_ID, id)
  },

  getAdminId() {
    return localStorage.getItem(ADMIN_ID)
  },
}

export default TokenService
