import { TENANT_GROUPS } from '@/config'

const state = {
  sidebarVisible: '',
  sidebarUnfoldable: false,
  pointSettings: [],
  roles: [],
  role: {},
  user: {},
  user_admin: {},
  admin_role: [],
  users: [],
  permissions: [],
  permissionsModel: [],
  categoriesModel: [],
  optionsModel: [],
  permissionsSelect: [],
  categoriesSelect: [],
  filter: {},
  categories: [],
  apps: [],
  products: [],
  trainings: [],
  category: {},
  langs: [],
  category_update: [],
  product_update: [],
  product: {},
  vendors: [],
  file: {},
  vendorsSelect: [],
  attributes: [],
  attribute: {},
  options: [],
  optionsSelect: [],
  attributesSelect: [],
  attributesModel: [],
  option: {},
  imageFile: {},
  isLoading: false,
  files: [],
  userFiles: [],
  import: false,
  userModel: {},
  productsSelect: [],
  vendorList: [],
  vendor: {},
  suppliersList: [],
  supplier: {},
  suppliersSelect: [],
  suppliers: [],
  rolesSelect: [],
  addressStreet: {},
  addressCity: {},
  training: {},
  questions: [],
  bTrainings: [],
  bTraining: {},
  bTrainingTypes: {},
  bTrainingCosts: {},
  bTrainingsDetails: [],
  pagination: [],
  paginationActivity: [],
  paginationGlobalActivity: [],
  groupsModel: [],
  parentAttributes: [],
  groups: [],
  group: null,
  slides: [],
  slide: {},
  orders: [],
  messages: [],
  message: {},
  threads: [],
  order: null,
  textPages: [],
  textPage: {},
  setting: null,
  featured_products: [],
  toast: [],
  isPending: false,
  threadsBySlug: [],
  threadsUsers: [],
  stats: [],
  trainingResults: [],
  bTrainingResults: [],
  externalTrainingResults: [],
  hunterResults: [],
  newsFeed: [],
  newsFeeds: [],
  warehouses: [],
  warehouseCount: 0,
  salesPromotions: [],
  menuVisible: true,
  importedProducts: [],
  importedProductsTotal: 0,
  hunterDetails: [],
  hunterDetail: [],
  dealers: [],
  customer: {},
  points: false,
  boosterFields: [],
  wholesalers: [],
  userOrders: [],
  bankStatement: [],
  loginHistory: [],
  loginsHistory: [],
  detetedUsers: [],
  prices: [],
  price: {},
  priceCategories: [],
  priceList: [],
  singlePriceList: {},
  staffs: [],
  requestStats: [],
  inactiveUsers: [],
  complaints: [],
  complaint: {},
  certificateTexts: [],
  activities: [],
  activitiesGlobal: [],
  activityFilters: {},
  csNumberToApprove: [],
  messageIbanStatus: [],
  statusMatch: '',
  boostersList: [],
  inactiveBoosters: [],
  boostersPreview: [],
  userBoosterSelect: [],
  trainingFilters: [],
  promoFilters: [],
  orderStats: [],
  checks: [],
  userFilters: [],
  menuList: [],
  mediaCategories: [],
  medias: [],
  mediaPage: {},
  mediaPageFiles: [],
  uberallResults: [],
  parentGroups: [],
  wholesalerManagements: [],
  connections: [],
  defaultBanners: [],
  inActiveCustomerNumbers: [],
  initialUserRole: [],
  shopLimits: [],
  totalMediaFiles: 0,
  mediaFiles: [],
  voucherSelect: [],
  vouchers: [],
  brochureCategories: [],
  brochures: [],
  brochure: {},
  brochureFiles: [],
  surveys: [],
  surveysActivated: [],
  survey: [],
  survey_groups: [],
  filterUsers: {
    page: 1,
  },
  mainBoxes: [],
  mainBox: {},
  liteVersionCountries: TENANT_GROUPS.liteVersion,
  userApps: [],
  parentAttributes: [],
  parentAttributesPending: false,
}
export { state }
