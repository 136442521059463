import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import API from './config'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ApiService from './services/api.service'
import i18n from '@/plugins/i18n'
import Vue3Toastify, { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import PointsImporter from '@/plugins/points-importer'

ApiService.init(API.URL)

const app = createApp(App)
app.use(store)
app.use(i18n)
app.use(router)
app.use(CoreuiVue)
app.use(Vue3Toastify, {
  autoClose: 3000,
  position: toast.POSITION.TOP_RIGHT,
  transition: toast.TRANSITIONS.SLIDE,
})
app.use(VueAxios, axios)
app.use(PointsImporter, { store, i18n })
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)

app.mount('#app')
